import React from 'react'
import './AboutUsMission.css'
import AutoSlider from './AutoSlider'
import img1 from '../assets/images/missionimages/mission-img1.png'
import img2 from '../assets/images/missionimages/mission-img2.png'
import NavBar from './NavBar'
import SideBar from './SideBar'

const AboutUsMission = () => {
	return (
		<section className="wrapper-about">
			<div className="topbarmobile">
				<SideBar />
			</div>
			<div className="news-container align-sidebar-content">
				<div className="about-mission row col-lg-12 col-sm-12 col-md-12 col-12">
					<div className="col-lg-6 col-sm-6 col-md-6 col-12">
						<h1 className="text-white">Our Mission</h1>
						<div className="about-paragraphs">
							<p>
								We are a group of technology professionals, enthusiasts and market veteran who joined forces to
								create the leading consulting & advising company in the Middle East for Cryptocurrency,
								Blockchain technology and DeFi with special focus on NFT.
							</p>
							<p>
								Our aim is to educate and spread the knowledge about Blockchain Technology in the region. We aim
								to spread awareness about NFTs and its potential and opportunities that can bring to our
								businesses and economy.
							</p>
						</div>
					</div>
					<div className="col-lg-6 col-sm-6 col-md-6 col-12">
						<img src={img1} alt="mision1" className="mission-img1"></img>
					</div>
				</div>
				<div className="content-about row col-lg-12 col-sm-12 col-md-12 col-12">
					<div className="col-lg-6 col-sm-6 col-md-6 col-12">
						{/* <div className="mission-img2"></div> */}
						<img src={img2} alt="mision1" className="mission-img2"></img>
					</div>
					<div className="col-lg-6 col-sm-6 col-md-6 col-12">
						<h1 className="text-white">Our Process</h1>
						<div className="about-paragraphs">
							<p className="">
								Based on our deep Knowledge in this space, Market understanding and Connections, we are aiming
								to create multiple initiative and projects in the region with our partners to promote and push
								forward the Crypto & NFT adoption.
							</p>
							<h3 className="text-white">Consulting and Advising</h3>
							<p className="">
								ColossalBit is the go-to when it comes to Blockchain technology and NFTs, We work closely with
								our partners and customers in UAE and the region in order to make sure that we meet their
								expectations, vision and help them achieve their goals
							</p>
							<h3 className="text-white">Investing in other projects:</h3>
							<p className="">
								ColossalBit has already multiple investment in the crypto & NFT space with current running
								projects like mining and other future upcoming projects from NFT, wallets and marketplace.
							</p>
							<h3 className="text-white">Building our Own projects in the space:</h3>
							<p className="">
								ColossalBit is working on multiple proprietary projects that we aim to build in the pipeline.
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default AboutUsMission
