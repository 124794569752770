import React, { useState, useEffect } from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import {
   useLocation,
   useMatch,
   useResolvedPath,
   Link as Anchor,
} from 'react-router-dom'

import './Navbar.css'
import { Link } from 'react-scroll'
import { useParams} from 'react-router-dom'

const CustomLink = ({ children, to, ...props }) => {
   let resolved = useResolvedPath(to)
   let match = useMatch({ path: resolved.pathname, end: true })

   return (
      <Anchor
         style={{
            color: match ? '#ffd358' : '#fff',
            borderLeft: match ? '2px solid #ffd358' : '',
            fontWeight: match ? 700 : 500
         }}
         to={to}
         {...props}
      >
         {children}
      </Anchor>
   )
}

function NavBar() {
   const [isPageActive, setIsPageActive] = useState(false)
   const [ notHome, setNotHome ] = useState(false);
   const location = useLocation()
   const params = useParams();

   const handleSetActive = to => {
      console.log(to)
   }

   useEffect(() => {
      const { pathname } = location

      if (
         pathname === '/contactus' ||
         pathname === '/newsandevents' ||
         pathname === '/aboutus' ||
         pathname === '/projects' ||
         pathname === `/projects/${params.id}`||
         pathname === '/nftconsultancy' ||
         pathname === '/blogs' ||
         pathname === '/videointerview' ||
         pathname === "/metaversepackage"
      ) {
         setNotHome(true);
         return
      }
      else {
         setNotHome(false);
      }

      setIsPageActive(true)
   }, [location])
   return (
      <div className='showMobileNav'>
         <Navbar>
            <Container>
               <Nav className={!notHome ? 'mr-auto' : 'mr-auto align-navbar'}>
                  <CustomLink className={!notHome ? 'navabar-home-' : 'navbar-home'} to='/'>Home</CustomLink>
                  {isPageActive && (
                     <>
                        <Link
                           to='services'
                           onClick={handleSetActive}
                        >
                           Our services
                        </Link>
                        <Link
                           to='partners'
                           onSetActive={handleSetActive}
                        >
                           Our partners
                        </Link>
                     </>
                  )}

                  <CustomLink to='/aboutus' className ={!notHome ? 'navbar-border-': 'navbar-border'}>About Us</CustomLink>
                  <CustomLink to='/newsandevents' className ={!notHome ? 'navbar-border-': 'navbar-border'}>News & Events</CustomLink>
                  <CustomLink to='/projects' className ={!notHome ? 'navbar-border-': 'navbar-border'}>Projects</CustomLink>
                  <CustomLink to='/contactus' className ={!notHome ? 'navbar-border-': 'navbar-border'}>Contact Us</CustomLink>
               </Nav>
            </Container>
         </Navbar>
      </div>
   )
}

export default NavBar
