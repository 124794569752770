import React from 'react'
import MetaversePckComponent from '../components/FooterPages/MetaversePckComponent';
import EventFooter from '../components/EventFooter';

function MetaVersePage() {
  return (
    <>
        <MetaversePckComponent/>
        <EventFooter />
    </>
  )
}

export default MetaVersePage