import metafestBanner from '../../assets/projects/banners/metafest.png'
import metafestLogo from '../../assets/projects/logo/metafest.png'
import metaFest from '../../assets/projects/METAFEST - UI  01.png'
import metaFestMain from '../../assets/projects/METAFEST - UI 02.png'

import daniAlvesBanner from '../../assets/projects/banners/dani.png'
import daniLogo from '../../assets/projects/logo/dani_alves.png'
import daniAlves from '../../assets/projects/ALVES COLLECTION NFT - UI 01.png'
import daniAlvesMain from '../../assets/projects/ALVES COLLECTION NFT - UI 02.png'
import daniAlvesVideo from '../../assets/projects/videos/dani.mp4'

import iSuitBanner from '../../assets/projects/banners/isuit.png'
import iSuitVideo from '../../assets/projects/videos/isuit.mp4'
import xmenLogo from '../../assets/projects/logo/xMen.png'
import iSuit from '../../assets/projects/ISUIT - UI 01.png'
import iSuitMain from '../../assets/projects/ISUIT - UI 02.png'

import metaterraceBanner from '../../assets/projects/banners/metaterrace.png'
import metaterraceLogo from '../../assets/projects/logo/metaterrace.png'
import metaTerrace from '../../assets/projects/METATERRACE - UI 01.png'
import metaTerraceMain from '../../assets/projects/METATERRACE - UI 02.png'
import metateraceVideo from '../../assets/projects/videos/metaterrace.mp4'

import nftConnectBanner from '../../assets/projects/banners/nft_connect.png'
import nftConnectLogo from '../../assets/projects/logo/nf-connect.png'
import nftConnect from '../../assets/projects/NFT CONNECT - UI 01.png'
import nftConnectMain from '../../assets/projects/NFT CONNECT - UI 02.png'
import nftConnectApp from '../../assets/projects/NFT CONNECT APP - UI 01.png'
import nftConnectMainApp from '../../assets/projects/NFT CONNECT APP - UI 02.png'
import nftConnectAppVideo from '../../assets/projects/videos/nftConnect.mp4'

import nftConnectMobileBanner from '../../assets/projects/banners/NFT-Connect-mobile.png'

import nftMarketCapBanner from '../../assets/projects/banners/roaylity_shop.png'
import nftMarketCapLogo from '../../assets/projects/logo/marketcap.png'
import marketCap from '../../assets/projects/NFT MARKETCAP - UI 01 (1).png'
import marketCapMain from '../../assets/projects/NFT MARKETCAP - UI 02.png'

import pabloBanner from '../../assets/projects/banners/pablo.png'
import pabloLogo from '../../assets/projects/logo/pablo.png'
import pabloEscobar from '../../assets/projects/PABLO ESCOBAR - UI 01.png'
import pabloEscobarMain from '../../assets/projects/PABLO ESCOBAR - UI 02.png'
import pabloVideo from '../../assets/projects/videos/pablo.mp4'

import kaloBanner from '../../assets/projects/banners/kalo.png'
import kaloLogo from '../../assets/projects/logo/kalo.png'
import kaloScope from '../../assets/projects/KALOSCOPE - UI 01.png'
import kaloScopeMain from '../../assets/projects/KALOSCOPE - UI 02.png'
import kaloVideo from '../../assets/projects/videos/kalo.mp4'

import marketPlaceBanner from '../../assets/projects/banners/marketplace.png'
import marketPlaceBannerGolden from '../../assets/projects/banners/nft-market-cap.png'
import marketWhiteLogo from '../../assets/projects/logo/market_white.png'
import marketLogoArabic from '../../assets/projects/logo/nft_marketplace.png'
import marketLogoEnglish from '../../assets/projects/logo/nft_marketplace_english.png'
import marketplace from '../../assets/projects/NFT MARKETPLACE - UI 01.png'
import marketplaceMain from '../../assets/projects/NFT MARKETPLACE - UI 02.png'

import netflix from '../../assets/projects/banners/netflix.png'
import netflixLogo from '../../assets/projects/logo/netflix.png'

import galacticBanner from '../../assets/projects/banners/galactic.png'
import galacticLogo from '../../assets/projects/logo/galactic.png'
import metaGalacticMain from '../../assets/projects/META GALATIC FC PROJECT - UI 01.png'
import metaGalactic from '../../assets/projects/META GALATIC FC PROJECT - UI 02.png'
import galacticVideo from '../../assets/projects/videos/galactic.mp4'

import metasiri from '../../assets/projects/METASRI - UI 01.png'
import metasiriMain from '../../assets/projects/METASRI - UI 02.png'

import kutoBanner from '../../assets/projects/banners/kutto.png'
import kutoLogo from '../../assets/projects/logo/kuto.png'
import kuto from '../../assets/projects/KUTOS - UI 01.png'
import kutoMain from '../../assets/projects/KUTOS - UI 02.png'

import swaggerBanner from '../../assets/projects/banners/swagger.png'
import swaggerLogo from '../../assets/projects/logo/swagger.png'
import swagger from '../../assets/projects/SWAGGER TOOF - UI 01.png'
import swaggerMain from '../../assets/projects/SWAGGER TOOF - UI 02.png'

import royalityLogo from '../../assets/projects/logo/royality.png'
import royalityBanner from '../../assets/projects/banners/royality_demo.png'

import collosalBitLogo from '../../assets/projects/logo/collosalBit.png'
import colossolBitBanner from '../../assets/projects/banners/colossolBit.png'

export const projects = [
	{
		id: 1,
		banner: metafestBanner,
		icon: metafestLogo,
		title: 'METAFEST',
		content:
			'It allows the artist to minted nft to be sold via auction. At live NFT auction 15 artist will auction there work both bidding on online and offline.',
		type: 'blockchain',
		projectpc: metaFest,
		screenshot: metaFestMain,
		linkText: 'auction.metafest.ch',
		link: 'https://auction.metafest.ch',
		platform: 'Web, Android & IOS',
		technology: [
			{
				tech: 'NFT auction',
			},
			{
				tech: 'Python',
			},
			{
				tech: 'ReactJs',
			},
			{
				tech: 'Solidty',
			},
			{
				tech: 'ERC 721, ERC 1155',
			},
		],
		description:
			'<span style="font-family: Gotham-Book">Caprices Festival has teamed up with award-winning cryptocurrency app SwissBorg to host an innovative and forward thinking summit to kick things off before the main event which will integrate the art and blockchain world through themes like NFT and the Metaverse. At the live NFT auction 15 artist will auction there work both bidding on online and offline. The platform allows the artist to minted nft to be sold via auction.</span>',
	},

	{
		id: 2,
		banner: daniAlvesBanner,
		icon: daniLogo,
		title: 'DANI ALVES',
		bannerType: 'video',
		videoSrc: daniAlvesVideo,
		content:
			'An exclusive Watch NFT Collection, launched for Dani Alves in partnership with backes & strauss luxury diamond watch maker.',
		type: 'nft',
		projectpc: daniAlves,
		screenshot: daniAlvesMain,
		linkText: 'nft.backesandstrauss.com',
		link: 'https://nft.backesandstrauss.com',
		platform: 'Web, Android & IOS',
		technology: [
			{
				tech: 'Python',
			},
			{
				tech: 'ReactJs',
			},
			{
				tech: 'Solidty',
			},
			{
				tech: 'ERC 721, ERC 1155',
			},
		],
		description: `<div style="padding-bottom:1rem" ><span style="font-family: Gotham-Book">Dani Alves exclusive NFT watch collection: </span></div><div> <span style="font-family: Gotham-Book">An exclusive Watch NFT Collection, launched for Dani Alves in partnership with backes & strauss luxury diamond watch maker. The exclusive collection for 38 watches will be released by Dani Alves on a special occasion. The fan will be able to access the nft and get a chance to get merchandise.</span></div>`,
	},

	{
		id: 3,
		banner: iSuitBanner,
		bannerType: 'video',
		videoSrc: iSuitVideo,
		icon: xmenLogo,
		title: 'ISUIT',
		content:
			'An exclusive Watch NFT Collection, launched for Dani Alves in partnership with backes & strauss luxury diamond watch maker.',
		type: 'nft',
		projectpc: iSuit,
		screenshot: iSuitMain,
		linkText: 'nft.backesandstrauss.com',
		link: 'https://nft.backesandstrauss.com',
		platform: 'Web, Android & IOS',
		technology: [
			{
				tech: 'Python',
			},
			{
				tech: 'ReactJs',
			},
			{
				tech: 'Solidty',
			},
			{
				tech: 'ERC 721, ERC 1155',
			},
			{
				tech: 'Crypto payment solution integrated',
			},
			{
				tech: 'AR / VR integrated',
			},

			{
				tech: 'Metaverse experience, connecting physical to digital',
			},
		],
		description: `<div style="padding-bottom:.56rem" >
    <span style="font-family: Gotham-Book">Dani Alves exclusive NFT watch collection: </span></div><div>

    <span style="font-family: Gotham-Book">An exclusive Watch NFT Collection, launched for Dani Alves in partnership with backes & strauss luxury diamond watch maker. The exclusive collection for 38 watches will be released by Dani Alves on a special occasion. The fan will be able to access the nft and get a chance to get merchandise.</span>
    </div>`,
	},

	{
		id: 4,
		banner: metaterraceBanner,
		icon: metaterraceLogo,
		bannerType: 'video',
		videoSrc: metateraceVideo,
		noWatermark: true,

		title: 'METATERRACE',
		content: `MetaTerrace is described as a “one-of-a-kind” establishment converging the digital and physical worlds in line with the principle of the metaverse.`,
		type: 'meta',
		projectpc: metaTerrace,
		screenshot: metaTerraceMain,
		linkText: 'https://metaterrace.io/',
		link: 'https://metaterrace.io/',
		platform: 'Web, Android & IOS',
		technology: [
			{
				tech: 'Python',
			},
			{
				tech: 'ReactJs',
			},
			{
				tech: 'Solidty',
			},
			{
				tech: 'ERC 721, ERC 1155',
			},
			{
				tech: 'Crypto payment solution integrated',
			},
			{
				tech: 'AR /VR integrated',
			},
			{
				tech: 'Metaverse experience, connecting physical to digital',
			},
		],
		description: `<div style="padding-bottom:5px"> <span style="font-family: Gotham-Book">MetaTerrace is described as a “one-of-a-kind” establishment converging the digital and physical worlds in line with the principle of the metaverse.</span></div>
        <div style="padding-bottom:5px" > <span style="font-family: Gotham-Book">The MetaTerrace will have it own VR room that will facilitate “metaverse experience”. The VR glasses will enable people to visit alternative worlds, have drinks, engage in NFT and crypto discussions — all at the fingertips, on a futuristic MetaTerrace.</span></div>
        <div style="padding-bottom:5px" > <span style="font-family: Gotham-Book">The inaugural event will also feature live DJ music and will be broadcast by Ibiza Global Radio. </span></div>
        <div style="padding-bottom:5px" > <span style="font-family: Gotham-Book">The MetaTerrace VR room will continue to be available in 2022 so people will be able interact and enjoy the metaverse experience.</span></div>
        <div style="padding-bottom:5px" > <span style="font-family: Gotham-Book">Own your own space in the Metaverse. Kaloscope spaces are limited edition NFTs that allow you to display your existing NFTs in a digital space which can be shared interactively with anyone. </span></div>
        <div style="padding-bottom:5px" > <span style="font-family: Gotham-Book">Spaces will come in many themes and collaborations with other brands. Users can connect, create and play games to earn rewards!. </span></div>`,
	},

	{
		id: 11,
		banner: nftConnectBanner,
		icon: nftConnectLogo,
		title: 'NFT Connect',
		content:
			'Connecting You To Greatness Discovering Dope NFT Artists DAILY to curate exclusive drops for celebrity partners! @nft.promo. 8 posts. 88 followers.',
		type: 'blockchain',
		projectpc: nftConnectMain,
		screenshot: nftConnect,
		linkText: 'https://nftconnect.ai/',
		link: 'https://nftconnect.ai/',
		platform: 'Web',
		technology: [
			{
				tech: 'Python',
			},
			{
				tech: 'ReactJs',
			},
			{
				tech: 'Solidty',
			},
			{
				tech: 'ERC 721, ERC 1155',
			},
		],
		description: `<div style="padding-bottom:1rem" ><span style="font-family: Gotham-Book">
    Pablo Escobar's exclusive NFT collection gives you access to an Exclusive, fully paid trip to Medellin and to Pablo Escobar Museum. This is Your ONLY chance to have this once-in-a-lifetime experience. You will get to see what the Netflix documentary did not show you. Get this NFT to have access to this extraordinary experience.</span></div>
        <div style="padding-bottom:1rem" ><span style="font-family: Gotham-Book">We will also be launching a Treasure hunt game in the future. NFT holders will get early access to the game and to Pablo Escobar Metaverse.</span></div>`,
	},

	{
		id: 11221,
		banner: nftConnectMobileBanner,
		icon: nftConnectLogo,
		title: 'NFT Connect MOBILE APP',
		bannerType: 'video',
		videoSrc: nftConnectAppVideo,
		content:
			'Connecting You To Greatness Discovering Dope NFT Artists DAILY to curate exclusive drops for celebrity partners! @nft.promo. 8 posts. 88 followers.',
		type: 'blockchain',
		projectpc: nftConnectMainApp,
		screenshot: nftConnectApp,
		linkText: 'https://nftconnect.ai/',
		link: 'https://nftconnect.ai/',
		platform: 'Web',
		technology: [
			{
				tech: 'Python',
			},
			{
				tech: 'ReactJs',
			},
			{
				tech: 'Solidty',
			},
			{
				tech: 'ERC 721, ERC 1155',
			},
		],
		description: `<div style="padding-bottom:1rem" ><span style="font-family: Gotham-Book">
    Pablo Escobar's exclusive NFT collection gives you access to an Exclusive, fully paid trip to Medellin and to Pablo Escobar Museum. This is Your ONLY chance to have this once-in-a-lifetime experience. You will get to see what the Netflix documentary did not show you. Get this NFT to have access to this extraordinary experience.</span></div>
        <div style="padding-bottom:1rem" ><span style="font-family: Gotham-Book">We will also be launching a Treasure hunt game in the future. NFT holders will get early access to the game and to Pablo Escobar Metaverse.</span></div>`,
	},

	{
		id: 2,
		banner: nftMarketCapBanner,
		icon: nftMarketCapLogo,
		title: 'NFT MarketCap',
		content:
			'It’s a website for tracking capitalization of NFT across multiple platform such as Opensea, Rarible and Solana.',
		type: 'nft',
		projectpc: marketCap,
		screenshot: marketCapMain,
		linkText: 'nftmarketcap.ai',
		link: 'https://nftmarketcap.ai',
		platform: 'Web, Android & IOS',
		technology: [
			{
				tech: 'Python',
			},
			{
				tech: 'ReactJs',
			},
			{
				tech: 'Solidty',
			},
			{
				tech: 'ERC 721, ERC 1155',
			},
			{
				tech: 'Support multi chain architecture - BNB Chain',
			},
			{
				tech: 'VM-compatible blockchains: Avalanche Contract Chain (C-Chain), BNB Chain, Fantom Opera and Polygon.',
			},
		],
		description: `<div style="padding-bottom:1rem"><span style="font-family: Gotham-Book">NFTmarketcap is a website for tracking capitalization of NFT across multiple platform such as Opensea, Rarible, Solana & across chains.</span></div>
                    <div style="padding-bottom:1rem"><span style="font-family: Gotham-Book">The page provides various data about several listed NFT, such as their price, available supply, trade volume over last 24 hours or market capitalization.</span></div>
                    <div><span style="font-family: Gotham-Book">The statistics are updated every 5 minutes. Market capitalization is calculated by multiplying price by total supply.</span></div>`,
	},

	{
		id: 12,
		banner: pabloBanner,
		icon: pabloLogo,
		videoSrc: pabloVideo,
		title: 'Pablo Escobar',
		bannerType: 'video',
		content:
			"Pablo Escobar's exclusive NFT collection gives you access to an Exclusive, fully paid trip to Medellin and to Pablo Escobar Museum. This is Your ONLY chance to have this once-in-a-lifetime experience.",
		type: 'blockchain',
		projectpc: pabloEscobarMain,
		screenshot: pabloEscobar,
		linkText: 'https://13secretsofpablo.io/',
		link: 'https://13secretsofpablo.io/',
		platform: 'Web',
		technology: [
			{
				tech: 'Python',
			},
			{
				tech: 'ReactJs',
			},
			{
				tech: 'Solidty',
			},
			{
				tech: 'ERC 721, ERC 1155',
			},
		],
		description: `<div style="padding-bottom:1rem" ><span style="font-family: Gotham-Book">
    Connecting and engaging global NFT leaders, proponents, investors, artists, collectors, CryptoPunks and Bored Apes. Our members share knowledge, experience and interests in all relevant areas such as Metaverse, NFT 2.0, Web3, NFT valuations, investing, risks, regulations, emerging trends, blockchain games, to name but a few.
    Get more engaged with the videos from past events!</span></div>
        <div style="padding-bottom:1rem" ><span style="font-family: Gotham-Book">We will also be launching a Treasure hunt game in the future. NFT holders will get early access to the game and to Pablo Escobar Metaverse.</span></div>`,
	},

	{
		id: 9,
		banner: kaloBanner,
		icon: kaloLogo,
		videoSrc: kaloVideo,
		noWatermark: true,
		title: 'KALOSCOPE',
		bannerType: 'video',
		content:
			'It allows users to connect their non-custodial wallet and purchase exclusive, limited edition NFT digital spaces and collectibles, alongside displaying their existing ones within their space.',
		type: 'meta',
		projectpc: kaloScope,
		screenshot: kaloScopeMain,
		linkText: 'kaloscope.io',
		link: 'https://kaloscope.io/',
		platform: 'Web, Android & IOS',
		technology: [
			{
				tech: 'Python',
			},
			{
				tech: 'ReactJs',
			},
			{
				tech: 'Solidty',
			},
			{
				tech: 'ERC 721, ERC 1155',
			},
		],
		description: `<div style="padding-bottom:1rem" ><span style="font-family: Gotham-Book">The Kaloscope platform enables users to connect their non-custodial wallet and purchase exclusive, limited edition NFT digital spaces and collectibles, alongside displaying their existing ones within their space. Users will be able to connect and interact with others and flex their most coveted NFTs in the metaverse.</span></div>
        <div style="padding-bottom:1rem" ><span style="font-family: Gotham-Book">Expand your journey and earn rewards through completing quests and games which can be unlocked with your NFTs. Hunt for resources to craft new NFTs and morph your existing ones to find rarer variants!</span></div>`,
	},

	{
		id: 3,
		banner: marketPlaceBanner,
		icon: marketWhiteLogo,
		title: 'NFT MARKETPLACE ',
		label: '(white label)',
		content:
			'This website offers new ways for businesses to gain profits in the digital world where NFTs can be resold, depending on the conditions of the NFT. ',
		type: 'nft',
		projectpc: marketplace,
		screenshot: marketplaceMain,
		linkText: '',
		link: '',
		platform: 'Web, Android & IOS',
		technology: [
			{
				tech: 'Python',
			},
			{
				tech: 'ReactJs',
			},
			{
				tech: 'Solidty',
			},
			{
				tech: 'ERC 721, ERC 115',
			},
			{
				tech: 'Support multi chain architecture - BNB Chain',
			},
			{
				tech: 'EVM-compatible blockchains: Avalanche Contract Chain (C-Chain), BNB Chain (BSC), Fantom Opera and Polygon.',
			},
		],
		description: `<div style="padding-bottom:1rem"><span style="font-family: Gotham-Book">The popularity gained by non-fungible tokens has encouraged business entities to create NFT marketplaces and marketplace development programs. This offers new ways for businesses to gain profits in the digital world. Depending on the business, these can be primary markets, where an NFT is sold for the first time, or secondary markets, where NFTs can be resold, depending on the conditions of the NFT</span>.</div>
       <div style="font-family: Gotham-Book">Key Features: </br>
       <ul  style="font-family: Gotham-Book"> •	Mint NFT support multichain </br>
        •	Buy NFT using cryptos & credit card </br>
        •	Buy NFT using cryptos & credit card </br>
        </ul>
       </div>`,
	},

	{
		id: 212,
		banner: marketPlaceBannerGolden,
		icon: marketLogoArabic,
		title: 'NFT MARKETPLACE',
		label: '(ARABIC)',
		content:
			'It’s a website for tracking capitalization of NFT across multiple platform such as Opensea, Rarible and Solana.',
		type: 'nft',
		projectpc: marketCap,
		screenshot: marketCapMain,
		linkText: 'nftmarketcap.ai',
		link: 'https://nftmarketcap.ai',
		platform: 'Web, Android & IOS',
		technology: [
			{
				tech: 'Python',
			},
			{
				tech: 'ReactJs',
			},
			{
				tech: 'Solidty',
			},
			{
				tech: 'ERC 721, ERC 1155',
			},
			{
				tech: 'Support multi chain architecture - BNB Chain',
			},
			{
				tech: 'VM-compatible blockchains: Avalanche Contract Chain (C-Chain), BNB Chain, Fantom Opera and Polygon.',
			},
		],
		description: `<div style="padding-bottom:1rem"><span style="font-family: Gotham-Book">NFTmarketcap is a website for tracking capitalization of NFT across multiple platform such as Opensea, Rarible, Solana & across chains.</span></div>
                    <div style="padding-bottom:1rem"><span style="font-family: Gotham-Book">The page provides various data about several listed NFT, such as their price, available supply, trade volume over last 24 hours or market capitalization.</span></div>
                    <div><span style="font-family: Gotham-Book">The statistics are updated every 5 minutes. Market capitalization is calculated by multiplying price by total supply.</span></div>`,
	},

	{
		id: 212,
		banner: marketPlaceBannerGolden,
		icon: marketLogoEnglish,
		title: 'NFT MARKETPLACE',
		label: '(ENGLISH)',
		content:
			'It’s a website for tracking capitalization of NFT across multiple platform such as Opensea, Rarible and Solana.',
		type: 'nft',
		projectpc: marketCap,
		screenshot: marketCapMain,
		linkText: 'nftmarketcap.ai',
		link: 'https://nftmarketcap.ai',
		platform: 'Web, Android & IOS',
		technology: [
			{
				tech: 'Python',
			},
			{
				tech: 'ReactJs',
			},
			{
				tech: 'Solidty',
			},
			{
				tech: 'ERC 721, ERC 1155',
			},
			{
				tech: 'Support multi chain architecture - BNB Chain',
			},
			{
				tech: 'VM-compatible blockchains: Avalanche Contract Chain (C-Chain), BNB Chain, Fantom Opera and Polygon.',
			},
		],
		description: `<div style="padding-bottom:1rem"><span style="font-family: Gotham-Book">NFTmarketcap is a website for tracking capitalization of NFT across multiple platform such as Opensea, Rarible, Solana & across chains.</span></div>
                    <div style="padding-bottom:1rem"><span style="font-family: Gotham-Book">The page provides various data about several listed NFT, such as their price, available supply, trade volume over last 24 hours or market capitalization.</span></div>
                    <div><span style="font-family: Gotham-Book">The statistics are updated every 5 minutes. Market capitalization is calculated by multiplying price by total supply.</span></div>`,
	},

	{
		id: 634,
		banner: netflix,
		icon: netflixLogo,
		title: 'THE PURSUIT OF GREATNESS',
		content:
			'French - Algerian Filmmaker Asri Bendacha and American aspiring rapper Jon Rise embark on the road trip of a lifetime.',
		type: 'nft',
		projectpc: metasiri,
		screenshot: metasiriMain,
		linkText: 'http://metasri.io/',
		link: 'http://metasri.io/',
		platform: 'Web, Android & IOS',
		technology: [
			{
				tech: 'Python',
			},
			{
				tech: 'ReactJs',
			},
			{
				tech: 'Solidty',
			},
			{
				tech: 'ERC 721, ERC 1155',
			},
		],
		description:
			'<span style="font-family: Gotham-Book">French - Algerian Filmmaker Asri Bendacha and American aspiring rapper Jon Rise embark on the road trip of a lifetime, from LA to Houston, Atlanta to Miami, sharing one car but on parallel journeys. The NFT collection will be launched as exclusive ticket holders. The nft holder will be able to fund the growth of the Rapper Jon Rise, the revenue generated from this drop will be airdrop to the nft holders in future.</span> ',
	},

	{
		id: 13,
		banner: galacticBanner,
		icon: galacticLogo,
		title: 'MetaGalactic',
		bannerType: 'video',
		videoSrc: galacticVideo,
		content:
			"Pablo Escobar's exclusive NFT collection gives you access to an Exclusive, fully paid trip to Medellin and to Pablo Escobar Museum. This is Your ONLY chance to have this once-in-a-lifetime experience.",
		type: 'meta',
		projectpc: metaGalacticMain,
		screenshot: metaGalactic,
		linkText: '',
		link: '',
		platform: 'Web',
		technology: [
			{
				tech: 'Python',
			},
			{
				tech: 'ReactJs',
			},
			{
				tech: 'Solidty',
			},
			{
				tech: 'ERC 721, ERC 1155',
			},
		],
		description: `<div style="padding-bottom:1rem" ><span style="font-family: Gotham-Book">
    Connecting and engaging global NFT leaders, proponents, investors, artists, collectors, CryptoPunks and Bored Apes. Our members share knowledge, experience and interests in all relevant areas such as Metaverse, NFT 2.0, Web3, NFT valuations, investing, risks, regulations, emerging trends, blockchain games, to name but a few.
    Get more engaged with the videos from past events!</span></div>
        <div style="padding-bottom:1rem" ><span style="font-family: Gotham-Book">We will also be launching a Treasure hunt game in the future. NFT holders will get early access to the game and to Pablo Escobar Metaverse.</span></div>`,
	},

	{
		id: 123,
		banner: kutoBanner,
		icon: kutoLogo,
		title: 'NFT Minting Page',
		content:
			"Pablo Escobar's exclusive NFT collection gives you access to an Exclusive, fully paid trip to Medellin and to Pablo Escobar Museum. This is Your ONLY chance to have this once-in-a-lifetime experience.",
		type: 'drop',
		projectpc: kuto,
		screenshot: kutoMain,
		linkText: '',
		link: '',
		platform: 'Web',
		technology: [
			{
				tech: 'Python',
			},
			{
				tech: 'ReactJs',
			},
			{
				tech: 'Solidty',
			},
			{
				tech: 'ERC 721, ERC 1155',
			},
		],
		description: `<div style="padding-bottom:1rem" ><span style="font-family: Gotham-Book">
    Connecting and engaging global NFT leaders, proponents, investors, artists, collectors, CryptoPunks and Bored Apes. Our members share knowledge, experience and interests in all relevant areas such as Metaverse, NFT 2.0, Web3, NFT valuations, investing, risks, regulations, emerging trends, blockchain games, to name but a few.
    Get more engaged with the videos from past events!</span></div>
        <div style="padding-bottom:1rem" ><span style="font-family: Gotham-Book">We will also be launching a Treasure hunt game in the future. NFT holders will get early access to the game and to Pablo Escobar Metaverse.</span></div>`,
	},

	{
		id: 7,
		banner: swaggerBanner,
		icon: swaggerLogo,
		title: 'SWAGGER TOOF',
		content:
			'Swagger Toof allows the financing of the archaeological reconstruction of this extinct species which will be exhibited in the museum.',
		type: 'nft',
		projectpc: swagger,
		screenshot: swaggerMain,
		linkText: '',
		link: '',
		platform: 'Web',
		technology: [
			{
				tech: 'Python',
			},
			{
				tech: 'ReactJs',
			},
			{
				tech: 'Solidty',
			},
			{
				tech: 'ERC 721, ERC 1155',
			},
		],
		description: `<div style="padding-bottom:1rem" ><span style="font-family: Gotham-Book">This NFT collection represents the skulls of the Tiger saber tooth,(alternatively spelled sabre-tooth) which is any member of various extinct groups of predatory therapsids, predominantly carnivoran mammals, that are characterized by long, curved saber-shaped canine teeth which protruded from the mouth when closed. Saber-toothed mammals have been found almost worldwide from the Eocene epoch to the end of the Pleistocene epoch 42 million years ago – 11,000 years ago. </span></div>
        <div style="padding-bottom:1rem" ><span style="font-family: Gotham-Book">Swagger Toof NFT skulls art collection will be launched on the Ethereum blockchain is unique, it allows the financing of the archaeological reconstruction of this extinct species which will be exhibited in the museum.</span></div>
        <div style="padding-bottom:1rem" ><span style="font-family: Gotham-Book"> By becoming the owner of this nft you will receive a lifetime membership to the museum as well as numerous benefits.</span></div>`,
	},

	{
		id: 74,
		banner: royalityBanner,
		icon: royalityLogo,
		title: 'NFT Royality Shop (Demo)',
		content:
			'Swagger Toof allows the financing of the archaeological reconstruction of this extinct species which will be exhibited in the museum.',
		type: 'nft',
		projectpc: swagger,
		screenshot: swaggerMain,
		linkText: '',
		link: '',
		platform: 'Web',
		technology: [
			{
				tech: 'Python',
			},
			{
				tech: 'ReactJs',
			},
			{
				tech: 'Solidty',
			},
			{
				tech: 'ERC 721, ERC 1155',
			},
		],
		description: `<div style="padding-bottom:1rem" ><span style="font-family: Gotham-Book">This NFT collection represents the skulls of the Tiger saber tooth,(alternatively spelled sabre-tooth) which is any member of various extinct groups of predatory therapsids, predominantly carnivoran mammals, that are characterized by long, curved saber-shaped canine teeth which protruded from the mouth when closed. Saber-toothed mammals have been found almost worldwide from the Eocene epoch to the end of the Pleistocene epoch 42 million years ago – 11,000 years ago. </span></div>
        <div style="padding-bottom:1rem" ><span style="font-family: Gotham-Book">Swagger Toof NFT skulls art collection will be launched on the Ethereum blockchain is unique, it allows the financing of the archaeological reconstruction of this extinct species which will be exhibited in the museum.</span></div>
        <div style="padding-bottom:1rem" ><span style="font-family: Gotham-Book"> By becoming the owner of this nft you will receive a lifetime membership to the museum as well as numerous benefits.</span></div>`,
	},

	{
		id: 7,
		banner: colossolBitBanner,
		icon: collosalBitLogo,
		title: 'CollosalBit Art (Demo)',
		content:
			'Swagger Toof allows the financing of the archaeological reconstruction of this extinct species which will be exhibited in the museum.',
		type: 'nft',
		projectpc: swagger,
		screenshot: swaggerMain,
		linkText: '',
		link: '',
		platform: 'Web',
		technology: [
			{
				tech: 'Python',
			},
			{
				tech: 'ReactJs',
			},
			{
				tech: 'Solidty',
			},
			{
				tech: 'ERC 721, ERC 1155',
			},
		],
		description: `<div style="padding-bottom:1rem" ><span style="font-family: Gotham-Book">This NFT collection represents the skulls of the Tiger saber tooth,(alternatively spelled sabre-tooth) which is any member of various extinct groups of predatory therapsids, predominantly carnivoran mammals, that are characterized by long, curved saber-shaped canine teeth which protruded from the mouth when closed. Saber-toothed mammals have been found almost worldwide from the Eocene epoch to the end of the Pleistocene epoch 42 million years ago – 11,000 years ago. </span></div>
        <div style="padding-bottom:1rem" ><span style="font-family: Gotham-Book">Swagger Toof NFT skulls art collection will be launched on the Ethereum blockchain is unique, it allows the financing of the archaeological reconstruction of this extinct species which will be exhibited in the museum.</span></div>
        <div style="padding-bottom:1rem" ><span style="font-family: Gotham-Book"> By becoming the owner of this nft you will receive a lifetime membership to the museum as well as numerous benefits.</span></div>`,
	},
]
