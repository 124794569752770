import React, { useState, useEffect } from "react";
import {
  useLocation,
  useMatch,
  useResolvedPath,
  NavLink,
  useParams,
  useNavigate,
  Link as Anchor,
} from "react-router-dom";
import { Cross as Hamburger } from "hamburger-react";
import { Link } from "react-scroll";
import NavBar from "./NavBar";
import logoDesktop from "../assets/images/logo.png";

function SideBar() {
  const params = useParams();
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [activeStateServices, setActiveServices] = useState(false);
  const [isPageActive, setIsPageActive] = useState(false);
  const [notHome, setNotHome] = useState(false);

  const services = () => {
    setActiveServices(true);
    console.log("act", activeStateServices);
  };
  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
  };

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const { pathname } = location;

    if (
      pathname === "/contactus" ||
      pathname === "/newsandevents" ||
      pathname === "/aboutus" ||
      pathname === "/projects" ||
      pathname === `/projects/${params.id}` ||
      pathname === "/nftconsultancy" ||
      pathname === "/blogs" ||
      pathname === "/videointerview" ||
      pathname === "/metaversepackage"
    ) {
      setNotHome(true);
      return;
    } else {
      setNotHome(false);
    }
    setIsPageActive(true);
  }, [location]);

  const redirectToHome = () => {
    navigate("/");
  };

  const handleClick = () => {
    setHamburgerOpen(false);
  };
  const CustomLink = ({ children, to, ...props }) => {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });

    return (
      <Anchor
        style={{
          color: match ? "#ffd358" : "#fff",
          fontWeight: match ? 700 : 500,
        }}
        to={to}
        {...props}
      >
        {children}
      </Anchor>
    );
  };
  return (
    <div>
      {notHome ? <NavBar /> : null}
      <div className="logo-desktop">
        <img src={logoDesktop} onClick={redirectToHome} alt="" />
      </div>
      <div className="showMobile">
        <div className="d-flex align-hamburger">
          <div className="logo" onClick={redirectToHome}></div>
          <div className="hamburger" onClick={toggleHamburger}>
            <Hamburger isOpen={hamburgerOpen} color="#fff" />
          </div>
        </div>
        {hamburgerOpen ? (
          <div className="container">
            <div className={!notHome ? "navigation" : "navigation-others"}>
            <div className='navigation-wrapper'>
                        <NavLink
                           to='/'
                           className='font-light text-xl text-white'
                        >
                           Home
                        </NavLink>
                     {isPageActive && (
                        <>
                              <Link
                                 to='services'
                                 onClick={services}
                                 className='font-light text-xl text-white'
                              >
                                 Our services
                              </Link>
                              <Link
                                 to='partners'
                                 className='font-light text-xl text-white'
                                 activeClass='te'
                              >
                                 Our partners
                              </Link>
                        </>
                     )}
                        <NavLink
                           to='/aboutus'
                           className='font-light text-xl text-white'
                        >
                           About Us
                        </NavLink>
                        <NavLink
                           to='/newsandevents'
                           className='font-light text-xl text-white'
                        >
                           News & Events
                        </NavLink>
                        <NavLink
                           to='/projects'
                           className='font-light text-xl text-white'
                        >
                           Projects
                        </NavLink>
                        <NavLink
                           to='/contactus'
                           className='font-light text-xl text-white'
                        >
                           Contact Us
                        </NavLink>
                  </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default SideBar;
