import React from 'react'
import { Route, Routes } from 'react-router-dom'
import './App.css'
import ScrollToTopRoute from './ScrollToTopRoute'
import BlogPage from './pages/BlogPage'
import ContactPage from './pages/ContactPage'
import EventAbout from './pages/EventAbout'
import HomeBanner from './pages/HomeBanner'
import MetaVersePage from './pages/MetaVersePage'
import NewsPage from './pages/NewsPage'
import NewsPageMore from './pages/NewsPageMore'
import NftConsultancy from './pages/NftConsultancy'
import ProjectDetailPage from './pages/ProjectDetailPage'
import Projects from './pages/Projects'
import VideoInterviewPage from './pages/VideoInterviewPage'

function App() {
	return (
		<>
			<ScrollToTopRoute>
				<Routes>
					<Route exact path="/" element={<HomeBanner />} />
					<Route path="/aboutus" element={<EventAbout />} />
					<Route path="/contactus" element={<ContactPage />} />
					<Route path="/newsandevents" element={<NewsPage />} />
					<Route path="/newsandevents/:id" element={<NewsPageMore />} />
					<Route path="/projects" element={<Projects />} />
					<Route path="/projects/:id" element={<ProjectDetailPage />} />
					<Route path="/nftconsultancy" element={<NftConsultancy />} />
					<Route path="/metaversepackage" element={<MetaVersePage />} />
					<Route path="/blogs" element={<BlogPage />} />
					<Route path="/videointerview" element={<VideoInterviewPage />} />
				</Routes>
			</ScrollToTopRoute>
		</>
	)
}

export default App
