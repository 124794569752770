import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faTwitter, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons'
import './EventFooter.css'
import { faArrowCircleUp } from '@fortawesome/free-solid-svg-icons'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { Link } from 'react-scroll'
import { useParams } from 'react-router-dom'
import footerLogo from '../assets/images/logo.png'

function EventFooter() {
	const params = useParams()
	const [isActive, setIsActive] = useState(false)

	const navigationScrollHandler = () => {
		window.scrollY >= 200 ? setIsActive(true) : setIsActive(false)
	}

	useEffect(() => {
		window.addEventListener('scroll', navigationScrollHandler)

		return () => window.removeEventListener('scroll', navigationScrollHandler)
	}, [])
	const [isPageActive, setIsPageActive] = useState(false)
	const navigate = useNavigate()
	const location = useLocation()

	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' })
	}

	const navigatetoHome = () => {
		navigate('/')
	}
	useEffect(() => {
		const { pathname } = location

		if (
			pathname === '/contactus' ||
			pathname === '/newsandevents' ||
			pathname === '/aboutus' ||
			pathname === '/projects' ||
			pathname === `/projects/${params.id}` ||
			pathname === '/nftconsultancy' ||
			pathname === '/blogs' ||
			pathname === '/videointerview'
		) {
			return
		}
		setIsPageActive(true)
	}, [location])

	const ourServices = () => {
		if (!isPageActive) {
			navigate('/')
		} else {
			return
		}
	}

	return (
		<footer className="event-footer-bg">
			<div className="container">
				{/* <hr className='horizontal-color'/> */}
				<div className="row justify-content-center">
					<div className="row col-lg-4">
						<div className="col-lg-12 col-sm-6 col-md-6 col-12 foot-desc">
							<img src={footerLogo} alt="" className="pulse-grow-on-hover" onClick={navigatetoHome} />
							{/* <div className='footer-logo pulse-grow-on-hover' onClick={navigatetoHome}></div> */}
							<div className="mobile-footer-text">
								Bringing the Blockchain & <br />
								CryptoCurrency knowledge to the <br />
								Middle East & the Arab World! <br />
							</div>
							<h2 className="header-color-footer-social">Follow Us on Social Media</h2>
							<div className="f_social_icons footer-content">
								<div className="d-flex">
									<a href="https://instagram.com/colossalbit.io?utm_medium=copy_link" target="blank">
										<FontAwesomeIcon icon={faInstagram} className="icons-a"></FontAwesomeIcon>
									</a>
									<a href="https://www.facebook.com/ColossalBit-105518128607174" target="blank">
										<FontAwesomeIcon icon={faFacebook} className="icons-a"></FontAwesomeIcon>
									</a>
									<a href="https://twitter.com/ColossalBit?t=Im-sTeITLijVDouOH29EaA&s=09" target="blank">
										<FontAwesomeIcon icon={faTwitter} className="icons-a"></FontAwesomeIcon>
									</a>
									<a href="https://www.linkedin.com/company/colossalbit/" target="blank">
										<FontAwesomeIcon icon={faLinkedin} className="icons-a"></FontAwesomeIcon>
									</a>
									<a href="https://www.youtube.com/channel/UCUWgEGeLqnASaaxaKRbvsuw/videos" target="blank">
										<FontAwesomeIcon icon={faYoutube} className="icons-a"></FontAwesomeIcon>
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className="row col-lg-8">
						<div className="quick-link-text">
							<span className="qui-text">Qui</span>ck Links
						</div>
						<div className="col-lg-4 col-sm-4 col-md-4 col-12 andro">
							<div className="f_widget dark_widget about-widget">
								<div className="">
									<h3 className="header-color-footer">OUR SERVICES</h3>
									<div className="footer-content links">
										<NavLink to="/nftconsultancy" className="font-light text-xl text-white">
											NFT Consultancy
										</NavLink>
										<NavLink to="/projects/1" className="font-light text-xl text-white">
											NFT Shop
										</NavLink>

										<NavLink to="/projects/3" className="font-light text-xl text-white">
											NFT Marketplace
										</NavLink>

										{/* <a  href="#" className="font-light text-xl text-white" target="_blank" 
                    rel="noreferrer" onClick={(e) => {e.preventDefault(); }}>
                      Metaverse Consultancy
                    </a> */}

										<NavLink to="/metaversepackage" className="font-light text-xl text-white">
											Metaverse Package
										</NavLink>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-sm-4 col-md-4 col-12 foot-desc">
							<h3 className="header-color-footer">KNOWLEDGE</h3>
							<div className="footer-content links">
								{/* <a  href="#" className="font-light text-xl text-white" target="_blank" 
                    rel="noreferrer" onClick={(e) => {e.preventDefault(); }}>
                     Media
                    </a>
                   
                    <NavLink
                        to="/blogs"
                        className="font-light text-xl text-white"
                      >
                       Blogs
                    </NavLink> */}

								<NavLink to="/newsandevents" className="font-light text-xl text-white">
									News & Events
								</NavLink>
								<NavLink to="/projects" className="font-light text-xl text-white">
									Projects
								</NavLink>

								<NavLink to="/videointerview" className="font-light text-xl text-white">
									Video Interview
								</NavLink>

								{/* <a
                  href="https://www.youtube.com/channel/UCUWgEGeLqnASaaxaKRbvsuw/videos"
                  className="font-light text-xl text-white"
                  target="_blank"
                  rel="noreferrer"
                >
                  YouTube Channel
                </a> */}
								{/* <a  href="#" className="font-light text-xl text-white" target="_blank" 
                    rel="noreferrer" onClick={(e) => {e.preventDefault(); }}>
                     Awards
                    </a>
                    <a  href="#" className="font-light text-xl text-white" target="_blank" 
                    rel="noreferrer" onClick={(e) => {e.preventDefault(); }}>
                     Roadmap
                    </a>
                    <a  href="#" className="font-light text-xl text-white" target="_blank" 
                    rel="noreferrer" onClick={(e) => {e.preventDefault(); }}>
                     Case studies
                    </a>
                   
                    <a  href="#" className="font-light text-xl text-white" target="_blank" 
                    rel="noreferrer" onClick={(e) => {e.preventDefault(); }}>
                     Resources
                    </a> */}
							</div>
						</div>
						<div className="col-lg-4 col-sm-4 col-md-4 col-12 foot-desc">
							<h3 className="header-color-footer">WEBSITE</h3>
							<div className="footer-content links">
								<NavLink to="/" className="font-light text-xl text-white">
									Home
								</NavLink>

								<Link to="services" className="font-light text-xl text-white" onClick={ourServices}>
									Our services
								</Link>

								<Link
									to="partners"
									className="font-light text-xl text-white"
									style={{ color: '#fff' }}
									activeClass="te"
									onClick={ourServices}
								>
									Our partners
								</Link>

								<NavLink to="/aboutus" className="font-light text-xl text-white">
									About Us
								</NavLink>

								<NavLink to="/contactus" className="font-light text-xl text-white">
									Contact Us
								</NavLink>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="footer-logo-align container">
				<div className="footer-text">Copyright © {new Date().getFullYear()} ColossalBit. All rights reserved</div>
				<div className={isActive ? 'opacity-100' : 'opacity-0'} onClick={scrollToTop}>
					<FontAwesomeIcon icon={faArrowCircleUp} className="scroll-top"></FontAwesomeIcon>
				</div>
			</div>
		</footer>
	)
}

export default EventFooter
