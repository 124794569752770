import React from 'react'
import ProjectDetailContent from '../components/Projects/ProjectDetailContent';
import EventFooter from '../components/EventFooter';

function ProjectDetailPage() {
  return (
    <>
        <ProjectDetailContent/>
        <EventFooter />
    </>
  )
}

export default ProjectDetailPage