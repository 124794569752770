import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { BsFillArrowRightCircleFill } from 'react-icons/bs'
import { NavLink, useNavigate } from 'react-router-dom'
import SwiperCore, { Autoplay } from 'swiper'
import waterMark from '../../assets/projects/banners/watermark.png'
import SideBar from '../SideBar'
import './ProjectContent.css'
import { projects } from './ProjectData'

import '../projects.css'

SwiperCore.use([Autoplay])

function ProjectContent() {
	const [wordEntered, setWordEntered] = useState('')
	const [data, setData] = useState()
	const [filteredData, setFilteredData] = useState()
	const [isClick, setIsClick] = useState('All')
	let navigate = useNavigate()

	useEffect(() => {
		setData(projects)
		setFilteredData(projects)
		setIsClick('All')
	}, [])

	const handleFilter = event => {
		const searchWord = event.target.value
		const newFilter = data.filter(item => {
			return item.title.toLowerCase().includes(searchWord.toLowerCase())
		})
		setWordEntered(searchWord)
		setFilteredData(newFilter)
	}

	const filterProjecttype = match => {
		if (match === 'All') {
			setIsClick(match)
			setFilteredData(data)
		} else {
			const updatedList = data.filter(item => item.type === match)
			setIsClick(match)
			setFilteredData(updatedList)
		}
	}

	const redirectToContactPage = () => {
		navigate('/contactus')
	}

	const FilterProjects = ({ filteredData = [] }) => {
		return (
			<>
				<div className="row">
					<div className="col-lg-12 col-sm-12 col-md-12 col-12 buttons btn-class-projects pb-5">
						<div
							style={{ width: 'max-content' }}
							className={`
                     ${isClick === 'All' ? 'highlight-color' : 'gray-clr'}
          btn btn-projects me-2 mb-2 col-lg-1 col-sm-2 col-md-2 col-6`}
							onClick={() => filterProjecttype('All')}
						>
							All
						</div>

						<div
							style={{ width: 'max-content' }}
							className={`
            ${isClick === 'drop' ? 'highlight-color' : 'gray-clr'}
            btn btn-projects me-2 mb-2 col-lg-2 col-sm-3 col-md-3 col-6`}
							onClick={() => filterProjecttype('drop')}
						>
							NFT Drop
						</div>

						<div
							style={{ width: 'max-content' }}
							className={`
            ${isClick === 'nft' ? 'highlight-color' : 'gray-clr'}
            btn btn-projects me-2 mb-2 col-lg-2 col-sm-3 col-md-3 col-6`}
							onClick={() => filterProjecttype('nft')}
						>
							NFT Project
						</div>

						<div
							style={{ width: 'max-content' }}
							className={`
            ${isClick === 'meta' ? 'highlight-color' : 'gray-clr'}
            btn btn-projects me-2 mb-2 col-lg-2 col-sm-3 col-md-3 col-6`}
							onClick={() => filterProjecttype('meta')}
						>
							Metaverse Project
						</div>
					</div>
				</div>

				{/* links */}
				<div className="project__grid">
					{filteredData.map((data, i) => {
						return (
							<div key={data.id} className="project__card">
								<NavLink to={`/projects/${data.id}`}>
									{data.bannerType === 'video' ? (
										<div className="video__container">
											{/* water mark goes here */}

											{!data.noWatermark && (
												<img className="watermark" src={waterMark} alt={`watermark banner not found`} />
											)}

											<video
												className="project__card_video"
												src={data.videoSrc}
												muted
												playsInline
												autoPlay
												loop
											/>
										</div>
									) : (
										<img
											className="project__card_image"
											src={data.banner}
											alt={`${data.title} banner not found`}
										/>
									)}

									<img className="project__card__logo" src={data.icon} alt={`${data.title} logo not found`} />

									<div className="project__card_content ">
										<h1 style={{ textTransform: 'uppercase' }}>{data.title}</h1>
										{data.label && <h1 style={{ fontSize: '14px', marginTop: '-5px' }}>{data.label}</h1>}
										<p className="line-clamp ">{data.content}</p>
										<div className="project__card_arrow">
											<div className="project__card_arrow_inner">
												<BsFillArrowRightCircleFill />
											</div>
										</div>
									</div>
								</NavLink>
							</div>
						)
					})}
				</div>
			</>
		)
	}
	return (
		<>
			<section className="projectbg">
				<div className="topbarmobile">
					<SideBar />
				</div>
				<div className="news-container">
					<div className="container project-wrapper">
						<h1>Our Projects</h1>
						<div className="d-flex  align-btn-search">
							<div className="text-white">Our successful projects</div>
						</div>
						<div
							style={{ gap: '20px' }}
							className="d-flex flex-column flex-md-row mt-2 mb-5 justify-content-md-between align-items-md-center"
						>
							<div>
								<button
									style={{ fontWeight: 'bolder' }}
									className="btn btn-contact-project"
									onClick={redirectToContactPage}
								>
									Contact Us
								</button>
							</div>

							<div className="searchInputs ">
								<FontAwesomeIcon icon={faSearch} className="search__icon"></FontAwesomeIcon>
								<input
									type="text"
									placeholder=""
									className="search-box search__input"
									value={wordEntered}
									onChange={handleFilter}
								/>
							</div>
						</div>
						<div className="filter-wrapper">
							<FilterProjects filteredData={filteredData} />
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export default ProjectContent
