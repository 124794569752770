import React from 'react'
import NftConsultancyComponent from '../components/FooterPages/NftConsultancyComponent';
import EventFooter from '../components/EventFooter';

function NftConsultancy() {
  return (
    <>
      <NftConsultancyComponent/>
      <EventFooter />
    </>
  )
}

export default NftConsultancy