import React from 'react'
import EventFooter from '../components/EventFooter';
import NewsPageBanner from  '../components/NewsEvents/NewsPageBanner';
import NewsPageContent from  '../components/NewsEvents/NewsPageContent';
import NewsPageStories from  '../components/NewsEvents/NewsPageStories';

function NewsPage() {
  return (
    <>
        {/* <NewsPageBanner/> */}
        <NewsPageContent/>
        {/* <NewsPageStories/> */}
        <EventFooter/>
    </>
  )
}

export default NewsPage