import React from 'react'
import { Container } from 'react-bootstrap'
import './EventSlider.css'
import partner1 from '../assets/images/partner_icons/partner1.png'
import partner2 from '../assets/images/partner_icons/partner2.png'
import partner3 from '../assets/images/partner_icons/partner3.png'
import partner4 from '../assets/images/partner_icons/partner4.png'
import partner5 from '../assets/images/partner_icons/partner5.png'
import partner6 from '../assets/images/partner_icons/partner6.png'
import partner7 from '../assets/images/partner_icons/partner7.png'
import partner8 from '../assets/images/partner_icons/partner8.png'
import partner9 from '../assets/images/partner_icons/partner9.png'
import partner10 from '../assets/images/partner_icons/partner10.png'

export default function EventSlider() {
	return (
		<section className="slider-bg" id="partners">
			<Container className="partners-align">
				<h2>Our Partners</h2>
				<div className="row">
					<img
						src={partner1}
						alt=""
						className="partner-logo col-lg-3 col-sm-6 col-md-6 col-12"
					/>
					<img
						src={partner2}
						alt=""
						className="partner-logo col-lg-3 col-sm-6 col-md-6 col-12"
					/>
					<img
						src={partner3}
						alt=""
						className="partner-logo col-lg-3 col-sm-6 col-md-6 col-12"
					/>
					<img
						src={partner4}
						alt=""
						className="partner-logo col-lg-3 col-sm-6 col-md-6 col-12"
					/>
				</div>
				{/* <div className="row middle-partner">
          <img alt="" className="partner-hide col-lg-3"/>
          <img src={partner5} alt="" className="partner-logo col-lg-3 col-sm-6 col-md-6 col-12"/>
          <img src={partner6} alt="" className="partner-logo col-lg-3 col-sm-6 col-md-6 col-12"/>
          <img alt="" className="partner-hide col-lg-3"/>
        </div> */}
				<div className="row mt-5">
					<img
						src={partner7}
						alt=""
						className="partner-logo col-lg-3 col-sm-6 col-md-6 col-12"
					/>
					<img
						src={partner8}
						alt=""
						className="partner-logo col-lg-3 col-sm-6 col-md-6 col-12"
					/>
					<img
						src={partner9}
						alt=""
						className="partner-logo col-lg-3 col-sm-6 col-md-6 col-12"
					/>
					<img
						src={partner10}
						alt=""
						className="partner-logo col-lg-3 col-sm-6 col-md-6 col-12"
					/>
				</div>
			</Container>
		</section>
	)
}
