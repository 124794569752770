import React from 'react'
import './NewsPage.css'
import images from './images'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay } from 'swiper'

// import bg from '../../assets/images/b-g.jpg'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/scrollbar'

SwiperCore.use([Autoplay])

function NewsPageMoreContent() {
   return (
      <>
         <section className='news-container'>
            <main className='detail-content'>
               <div className='detail-inner-content'>
                  <h1 className='detail__title'>
                     More and More People Stay Home as Coronavirus Spreads
                  </h1>

                  {/* <img
                     src={diomond}
                     className='diamond-image'
                     alt='diamon not found'
                     style={{ height: '450px' }}
                  /> */}
                  <p className=''>
                     People live better in big houses and in big clothes. I try
                     to contrast; life today is full of contrast. We have to
                     change! I am not interested in the past, except as the road
                     to the future. Give me time and I’ll give you a revolution.
                     I think the idea of mixing luxury and mass-market fashion
                     is very modern. I want people to be afraid of the women I
                     dress.
                  </p>
                  <h1 className='detail__title' style={{margin: 0}}>
                     Mass-market fashion is very modern
                  </h1>
                  <p className=''>
                     People live better in big houses and in big clothes. I try
                     to contrast; life today is full of contrast. We have to
                     change! I am not interested in the past, except as the road
                     to the future. Give me time and I’ll give you a revolution.
                     I think the idea of mixing luxury and mass-market fashion
                     is very modern. I want people to be afraid of the women I
                     dress.
                  </p>
               </div>
            </main>
         </section>
         {/* <img
            src={bg}
            className='diamond-image'
            alt='diamon not found'
            style={{ borderRadius: '0' }}
         /> */}
         <section style={{padding: '30px 0'}}>
            <div className='news-container'>
               <div className='grid-stories'>
                  <div className='stories-item'>
                  <h4 style={{color: '#000'}}>Discover</h4>

                     <h1 style={{color: '#000'}}>
                        Our beautiful <br /> stories
                     </h1>
                     <p style={{color: '#222'}}>
                        People live better in big houses and in big clothes. I
                        try to contrast; life today is full of contrast. We have
                        to change!...
                     </p>
                  </div>

                  <div className='stories-item stories-item-big'>
                     <div className='desktop'>
                        <Swiper
                           slidesPerView={3}
                           loop={true}
                           autoplay={{
                              delay: 2500,
                           }}
                           spaceBetween={15}
                           className='mySwiper'
                        >
                           {images.map((img, i) => (
                              <SwiperSlide>
                                 <figure key={i} className='fig'>
                                    <img src={img} alt=' not found' />
                                    <figcaption>
                                       Our beautiful <br /> stories
                                    </figcaption>
                                 </figure>
                              </SwiperSlide>
                           ))}
                        </Swiper>
                     </div>

                     {/* mobile Devices */}
                     <div className='mobile-swiper'>
                        <Swiper
                           loop={true}
                           slidesPerView={2}
                           spaceBetween={15}
                           autoplay={{
                              delay: 2500,
                           }}
                           className='mySwiper custom'
                           breakpoints={{
                              // when window width is >= 320px
                              320: {
                                 slidesPerView: 1,
                                 spaceBetween: 10,
                              },

                              620: {
                                 slidesPerView: 2,
                                 spaceBetween: 10,
                              },
                           }}
                        >
                           {images.map((img, i) => (
                              <SwiperSlide>
                                 <img src={img} alt=' not found' />
                              </SwiperSlide>
                           ))}
                        </Swiper>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   )
}

export default NewsPageMoreContent
