import React, { useRef, useState, useEffect } from "react";
import { Container, Row, Col, Nav, Navbar } from "react-bootstrap";
import "./EventBanner.css";
import followIcon from "../assets/images/follow-icon.png";
import NavBar from "./NavBar";
import desktopVideo from "../assets/videos/bg-video.mp4";
import mobileVideo from "../assets/videos/bg-mobile-video.mp4";
import SideBar from "./SideBar";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import scrollIcon from '../assets/images/scroll-icon.png';
import Slider from "react-slick";


function EventBanner() {
  var settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 7000,
  };
  const videoRef = React.useRef();
  React.useEffect(() => {
    videoRef.current.play();
  }, []);

  return (
    <>
     <Slider {...settings}>
          <div className="event-banner">
            <div className="video-container showDesktop">
              <video
                muted
                playsInline
                autoPlay
                loop
                ref={videoRef}
                className="bg-video"
              >
                <source src={desktopVideo} type="video/mp4" />
              </video>
            </div>
            <div className="video-container showMobile">
              <video
                muted
                playsInline
                autoPlay
                loop
                ref={videoRef}
                className="bg-video"
              >
                <source src={mobileVideo} type="video/mp4" />
              </video>
            </div>
            <SideBar />
            <div className="home-content">
              <div className="homepage-align">
                <div className="d-flex">
                <Col sm={1} lg={1}/>
                  <Col sm={8} lg={9}>
                    <div className="hero-content">
                      <header>
                        <div className="font-face-gm">Specialized </div>
                        <div className="font-face-bold">NFT & Metaverse</div>
                        <div className="font-face-gm">Consultancy</div>
                      </header>
                      <div className="icon-watch">
                        <a
                          href="https://www.instagram.com/colossalbit.io/"
                          target="blank"
                        >
                          <img src={followIcon} alt="foloow" />Follow Us
                        </a>
                      </div>
                    </div>
                  </Col>
                  <Col sm={3} lg={2} className="nav-home-align">
                    <NavBar />
                  </Col>
                </div>
              </div>
              <a href="#bottom-content" alt="" className="scroll-icon">
                  <img src={scrollIcon} className="scroll-icon" alt=""/>
              </a>
              <a href="#bottom-content-mobile" className="scroll-icon showMobile-logoMobile">
                <img src={scrollIcon} className="scroll-icon" alt=""/>
              </a>
            </div>
          </div>
          <div className="slider-wrpper-content">
            <div className="slider22">
            <div className="container">
            <SideBar />
            </div>
              <div className="slider-class-align">
                <div className="slider-flex d-flex col-lg-12 col-sm-12 col-md-12 col-12">
                  <div className="col-lg-5 col-sm-1 col-12"></div>
                  <div className="col-lg-5 col-sm-8 col-12 d-flex justify-content-center">
                    <div className="container slider2-content">
                      <header>
                        <div className="font-face-gm">Specialized</div>
                        <div className="font-face-bold">NFT</div>
                        <div className="font-face-gm">Consultancy</div>
                      </header>
                      <div className="nft-content col-lg-10">
                        What is an NFT? If you think an NFT is just a picture or
                        jpg the you’re missing the point! We help you see &
                        understand the potential of NFTs and its future
                        applications specifically in your own business.
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-3 col-12 d-flex">
                  <NavBar/>
                  </div>
                </div>
              </div>
            </div>
              <a href="#bottom-content" alt="" className="scroll-icon">
                  <img src={scrollIcon} className="scroll-icon" alt=""/>
              </a>
              <a href="#bottom-content-mobile" className="scroll-icon showMobile-logoMobile">
                <img src={scrollIcon} className="scroll-icon" alt=""/>
              </a>
          </div>
     </Slider>
    </>
  );
}

export default EventBanner;
