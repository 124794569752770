import React  from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie';
// import i18next from 'i18next'
// import { initReactI18next } from 'react-i18next'
// import HttpApi from 'i18next-http-backend'
// import LanguageDetector from 'i18next-browser-languagedetector'
import 'bootstrap/dist/js/bootstrap.js'

import 'react-rangeslider/lib/index.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css'
import 'flag-icon-css/css/flag-icons.min.css'
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import App from './App'
// i18next
//   .use(HttpApi)
//   .use(LanguageDetector)
//   .use(initReactI18next)
//   .init({
//     supportedLngs: ['en', 'ar', 'fr'],
//     fallbackLng: 'en',
//     debug: false,
//     // Options for language detector
//     detection: {
//       order: ['path', 'cookie', 'htmlTag'],
//       caches: ['cookie'],
//     },
//     // react: { useSuspense: false },
//     backend: {
//       loadPath: '/assets/locales/{{lng}}/translation.json',
//     },
//   })



ReactDOM.render(
  <CookiesProvider>
    <BrowserRouter>
        <App />
    </BrowserRouter>
  </CookiesProvider>,
  document.getElementById('root')
)

