import React, {useRef, useState} from 'react'
import { Container, Row, Col, Navbar, Nav } from 'react-bootstrap'
import './EventBanner.css';
import './AboutUsBanner.css'
import NavBar from './NavBar';
import SideBar from './SideBar';

function ContactBanner() {
  return (
    <div className="aboutus-banner">
         <SideBar/>
       <Container>
        <Row className='home-content'>
            <Col sm={6}>
              <div className='hero-content'>
                  <header><span className="font-face-bold">Contact Us</span></header>
                  <span className='home-paragraph'>
                  What is an NFT? If you think an NFT is just a picture or jpg the you’re missing the point! We help you see & understand the potential of NFTs and its future applications specifically in you own business
                  </span>
              </div>
            </Col>
            <Col sm={6}>
            <NavBar />
            </Col>
       </Row>
      </Container>
    </div>
  )
}

export default ContactBanner;