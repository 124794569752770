import React from "react";
import "./NftConsultancy.css";
import SideBar from "../SideBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function NftConsultancyComponent() {
  return (
    <section className="nftconsultant-bg">
      <div className="topbarmobile">
        <SideBar />
      </div>
      <div className="news-container">
        <div className="align-back-btn">
              <Link to="/"> <FontAwesomeIcon
                icon={faBackward}
                className="icon-back"
              ></FontAwesomeIcon><span className="font-face-bold">BACK</span></Link>
          </div>
        <div className="nftconsultancy-wrapper">
          <h1>NFT CONSULTING</h1>
          <p className="col-lg-8 col-sm-8 col-12 nftconsultant-paragraph">
            ColossalBit Management Consultancies is an NFT and Metaverse
            Advisory, Development and Investment Partnership Company based in
            Dubai.
          </p>
          <div className="nftconsults row">
            <div className="nftconsultsection col-lg-2 col-md-4 col-sm-4 col-8">
              <div className="icon-nft1" />
              <div className="text-nftsection">
                Consulting, Advising, and Education for NFT and Metaverse.
              </div>
            </div>
            <div className="nftconsultsection col-lg-2 col-md-4 col-sm-4 col-8">
              <div className="icon-nft2" />
              <div className="text-nftsection">
                Build a customized and dedicated NFT Shop for Artists,
                Businesses and Brands.
              </div>
            </div>
            <div className="nftconsultsection col-lg-2 col-md-4 col-sm-4 col-8">
              <div className="icon-nft3" />
              <div className="text-nftsection">
                Provide you presence and exposure in the Metaverse by booking
                your Crypto domains, Lands, and other...
              </div>
            </div>
            <div className="nftconsultsection col-lg-2 col-md-4 col-sm-4 col-8">
              <div className="icon-nft4" />
              <div className="text-nftsection">
                Build your customized Shop and Businesses in the Metaverse using
                VR, AR, XR.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NftConsultancyComponent;
