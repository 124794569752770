import React from 'react'
import AboutUsMission from '../components/AboutUsMission.js'
import EventFooter from '../components/EventFooter'
import EventMembers from '../components/EventMembers'

function EventAbout() {
	return (
		<>
			<AboutUsMission />
			{/* <EventMembers title="The Team behind ColossalBit" forceMargin /> */}
			<EventFooter />
		</>
	)
}

export default EventAbout
