import React from 'react'
import './EventBannerLogo.css'

function EventBannerLogo() {
  return (
    <div className="wrapper-event">
      <div
        id="bottom-content"
        className="event-logo-service1 showDesktop-logoBanner"
      ></div>
      <div
        id="bottom-content-mobile"
        className="event-logo-service-mobile showMobile-logoMobile"
      ></div>
    </div>
  )
}

export default EventBannerLogo

