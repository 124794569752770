import React from 'react'
import './EventBannerService.css'
import icon1 from '../assets/images/serviceicons/icon1.png'
import icon2 from '../assets/images/serviceicons/icon2.png'
import icon3 from '../assets/images/serviceicons/icon3.png'
import icon4 from '../assets/images/serviceicons/icon4.png'
import main from '../assets/images/serviceicons/main1.png'

function EventBannerService() {
	return (
		<div id="services" className="event-banner-service">
			<div className="container">
				<div className="event-service-bottom showDesktop-banner">
					<header className="service-header">Our Services</header>
					<main className="d-flex items-center justify-content-center offer-content-round">
						<div className="border-class">
							<div className="border-class spin border-white"></div>
							<div>
								{/* first icon */}
								<div className="first-icon-div" style={{ left: '-260px' }}>
									<p className="base-para">
										NFT <br /> (Non-Fungible Token)
									</p>
									<span className="icon-class">
										<img src={icon1} alt="not found" className="w-full" />
									</span>
								</div>

								{/* second icon */}

								<div className="second-icon-div">
									<span className="icon-class">
										<img src={icon2} alt="not found" className="w-full" />
									</span>
									<p className="base-para text-center lg:text-right">
										Consulting, Advising & <br /> Educating
									</p>
								</div>

								{/* third icon */}
								<div className="third-icon-div" style={{ right: '-224px' }}>
									<span className="icon-class">
										<img src={icon3} alt="not found" className="w-full" />
									</span>
									<p className="base-para text-center">
										NFT & Metaverse <br /> Advisory
									</p>
								</div>

								{/* fourth icon */}
								<div className="fourth-icon-div">
									<p className="base-para">
										Crypto Advisory <br /> Services
									</p>
									<span className="icon-class">
										<img src={icon4} alt="not found" className="w-full" />
									</span>
								</div>
							</div>
							<img src={main} alt="not found" className="mainImg" />
						</div>
					</main>
				</div>
				{/* Movile  */}
				<div className="showMobile-banner">
					<header className="service-header">Our Services</header>
					<div className="container d-flex justify-content-center  col-12 mobile-icons">
						<div className="col-12 icons-mobile-align">
							<img src={icon1} alt="nft" />
							<p className="base-para">
								NFT <br />
								(Non-Fungible Token)
							</p>
						</div>
						<div className="col-12 icons-mobile-align">
							<img src={icon2} alt="nft" />
							<p className="base-para text-center lg:text-right">
								Consulting, Advising & <br /> Educating
							</p>
						</div>
						<div className="col-12 icons-mobile-align">
							<img src={icon3} alt="nft" />
							<p className="base-para text-center">
								NFT & Metaverse <br /> Advisory
							</p>
						</div>
						<div className="col-12 icons-mobile-align">
							<img src={icon4} alt="nft" />
							<p className="base-para">
								Crypto Advisory <br /> Services
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default EventBannerService
