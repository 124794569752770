import React from 'react'
import ScrollToTopRoute from '../ScrollToTopRoute'
import EventBanner from '../components/EventBanner'
import EventBannerLogo from '../components/EventBannerLogo'
import EventBannerService from '../components/EventBannerService'
import EventChoose from '../components/EventChoose'
import EventFooter from '../components/EventFooter'
import EventMembers from '../components/EventMembers'
import EventOffer from '../components/EventOffer'
import EventSlider from '../components/EventSlider'

const HomeBanner = () => {
	return (
		<>
			<ScrollToTopRoute>
				{/* <Slider/> */}
				{/* <EventHeader /> */}
				<EventBanner />
				<EventBannerLogo />
				<EventBannerService />
				<EventOffer />
				<EventChoose />
				{/* <EventMembers title="Our Team" /> */}
				<EventSlider />
				<EventFooter />
			</ScrollToTopRoute>
		</>
	)
}

export default HomeBanner
