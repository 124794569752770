import React from 'react';
import "./EventChoose.css"
import Marquee from 'react-fast-marquee'
import gulfnews from '../assets/images/gulf-news.png'
import msn from '../assets/images/msn.png'
import khaleej from '../assets/images/kahleej.png'
import business from '../assets/images/business.png'
import wtc from '../assets/images/wtc.png'
import cmc from '../assets/images/CMC.png'

function EventChoose() {
  return (
    <>
    <section className="event-choose">
      <div className='container col-lg-8 col-md-12'>
        <div className='event-choose-wrapper'>
        <header>Why Choose Us</header>
        <div className="colossal-logo"></div>
        <span className='container choose-text'>We know this Market! We've been in Crypto & Blockchain since early stages and we also know you! With our years of experience in the Markets, we understand YOUR business needs!</span>
        <div className='icon-align-choose'>
          <div className="col-lg-3 col-sm-3 col-md-3 col-12">
            <div className='icon-completed'></div>
            <span className='icon-choose-text'>Successfully completed projects</span>
          </div>
          <div className="col-lg-3 col-sm-3 col-md-3 col-12">
          <div className='icon-consultant'></div>
          <span className='icon-choose-text'>Highly Specialized Consultants</span>
          </div>
          <div className="col-lg-3 col-sm-3 col-md-3 col-12">
          <div className='icon-user'></div>
          <span className='icon-choose-text'>100% Satisfaction of our Customers</span>
          </div>
          <div className="col-lg-3 col-sm-3 col-md-3 col-12">
          <div className='icon-international'></div>
          <span className='icon-choose-text'>International Team Clients all over the world</span>
          </div>
          </div>
          <div className='featuredin-text'>AS FEATURED IN:</div>
        </div>
      </div>
      
    </section>
     <Marquee
     className='text-center text-sm  text-white'
     pauseOnHover
     speed={50}
     gradient={false}
  >
    <a href="https://gulfnews.com/business/corporate-news/dubais-colossalbit-makes-history-with-winning-bid-of-56000-for-the-worlds-first-augmented-reality-nft-mural-1.1634214873686" target="_blank"><img src={gulfnews} className="gulf-news-img" alt="news"></img></a>
    <img src={msn} className="msn" alt="news"></img>
    <a href="https://www.khaleejtimes.com/business/dubais-colossalbit-makes-history-by-winning-bid-of-56000-for-future-nft-dubai"  target="_blank"><img src={khaleej} className="" alt="news"/></a>
    <a href="https://www.arabianbusiness.com/industries/technology/dubai-to-launch-metaterrace-a-restaurant-that-straddles-the-virtual-and-physical-worlds" target="_blank"><img src={business} className="business" alt="news"></img></a>
    <a href="https://www.gulfood.com/speakers/christian-chalfoun" target="_blank"><img src={wtc} className="" alt="news"></img></a>
    <img src={cmc} className="coin-market-img" alt="news"></img>
  </Marquee>
  </>
  )
}

export default EventChoose