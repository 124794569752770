import React from "react";
import "./NftConsultancy.css";
import SideBar from "../SideBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import image1 from '../../assets/images/blogimages/img1.png';
import image2 from '../../assets/images/blogimages/img2.png';
import image3 from '../../assets/images/blogimages/img3.png';
import image4 from '../../assets/images/blogimages/img4.png';
import image5 from '../../assets/images/blogimages/img5.png';

function BlogComponent() {
  return (
    <section className="nftconsultant-bg">
         <div className="topbarmobile">
        <SideBar />
      </div>
      <div className="news-container">
        <div className="align-back-btn">
          <Link to="/">
            {" "}
            <FontAwesomeIcon
              icon={faBackward}
              className="icon-back"
            ></FontAwesomeIcon>
            <span className="font-face-bold">BACK</span>
          </Link>
        </div>
        <div className="blog-wrapper">
          <h1>BLOG</h1>
          <div className="container">
          <div className="row">
              <figure className='figure-anim col-lg-6 col-sm-6 col-md-6 col-12 pb-5'>
                  <img src={image1} alt=' not found' />
                  <figcaption>
                    <h4>Lorem lipsum</h4>
                    <span>Lorem Ipsum is simply dummy <br /> text of the printing and <br /> typesetting industry.</span>
                  </figcaption>
              </figure>
              <figure className='figure-anim col-lg-6 col-sm-6 col-md-6 col-12 pb-5'>
                  <img src={image2} alt=' not found' />
                  <figcaption>
                    <h4>Lorem lipsum</h4>
                    <span>Lorem Ipsum is simply dummy <br />  text of the printing and <br /> typesetting industry.</span>
                  </figcaption>
              </figure>
           </div>
           <div className="row">
              <figure className='figure-anim col-lg-4 col-sm-4 col-md-4 col-12 pb-5'>
                  <img src={image3} alt=' not found' />
                  <figcaption>
                    <h4>Lorem lipsum</h4>
                    <span>Lorem Ipsum is simply dummy <br /> text of the printing and <br /> typesetting industry.</span>
                  </figcaption>
              </figure>
              <figure className='figure-anim col-lg-4 col-sm-4 col-md-4 col-12 pb-5'>
                  <img src={image4} alt=' not found' />
                  <figcaption>
                    <h4>Lorem lipsum</h4>
                    <span>Lorem Ipsum is simply dummy <br /> text of the printing and <br /> typesetting industry.</span>
                  </figcaption>
              </figure>
              <figure className='figure-anim col-lg-4 col-sm-4 col-md-4 col-12 pb-5'>
                  <img src={image5} alt=' not found' />
                  <figcaption>
                    <h4>Lorem lipsum</h4>
                    <span>Lorem Ipsum is simply dummy <br /> text of the printing and <br /> typesetting industry.</span>
                  </figcaption>
              </figure>
           </div>
          </div>
          </div>
        </div>
    </section>
  )
}

export default BlogComponent