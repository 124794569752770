import React from 'react'
import EventFooter from '../components/EventFooter';
import NewsPageMoreBanner from  '../components/NewsEvents/NewsPageMoreBanner';
import NewsPageMoreContent from  '../components/NewsEvents/NewsPageMoreContent';

function NewsPageMore() {
  return (
    <>
      <NewsPageMoreBanner/>
      <NewsPageMoreContent />
      <EventFooter/>
   </>
  )
}

export default NewsPageMore