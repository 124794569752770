import React from 'react'
import { Modal, Button, Row, Col } from "react-bootstrap";
import './EventModal.css'

const EventModal = (props) => {
  return (
    <Modal show={props?.show} onHide={props?.handleClose} centered> 
    <Modal.Header closeButton>
      <Modal.Title></Modal.Title>
    </Modal.Header>
    {props?.item === "captcha" ? <Modal.Body>Please verify that you are a human</Modal.Body> : null }
    {props?.item === "success" ? <Modal.Body>Your details has been submitted successfully. We will get in touch with you shortly</Modal.Body> : null }
    {props?.item === "error" ? <Modal.Body>There is some error. Please try after some time.</Modal.Body> : null }
    <Modal.Footer>
      <Button variant="secondary" onClick={props?.handleClose}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
  )
}

export default EventModal;
