import React from 'react'
import ContactBanner from  '../components/ContactBanner';
import ContactFooter from '../components/ContactFooter';
import EventFooter from '../components/EventFooter';

function ContactPage() {
  return (
    <>
      {/* <ContactBanner/> */}
      <ContactFooter/>
      <EventFooter />
    </>
  )
}

export default ContactPage