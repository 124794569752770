import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import NavBar from '../NavBar';
import SideBar from '../SideBar';

function ContactBanner() {
  return (
    <div className="news-banner">
         <SideBar/>
          {/* <div id="google_translate_element"></div> */}
       <Container>
        <Row className='home-content'>
            <Col sm={6}>
              <div className='hero-content'>
                  <header><span className="font-face-bold">News & Events</span></header>
              </div>
            </Col>
            <Col sm={6}>
              <NavBar />
            </Col>
       </Row>
      </Container>
    </div>
  )
}

export default ContactBanner;