import React from 'react'
import ProjectContent from '../components/Projects/ProjectContent'
import EventFooter from '../components/EventFooter'

function Projects() {
	return (
		<>
			<ProjectContent />
			<EventFooter />
		</>
	)
}

export default Projects
