import React from 'react'
import VideoComponent from '../components/FooterPages/VideoComponent';
import EventFooter from '../components/EventFooter';

function VideoInterviewPage() {
  return (
    <>
        <VideoComponent/>
        <EventFooter />
    </>
  )
}

export default VideoInterviewPage