import React from "react";
import "./NewsPage.css";
import { useNavigate } from "react-router-dom";
import mainImg from "../../assets/images/newsimages/news4.jpg";
import news01 from "../../assets/images/newsimages/news1.png";
import news02 from "../../assets/images/newsimages/news2.jpeg";
import news03 from "../../assets/images/newsimages/news3.jpg";
import news05 from "../../assets/images/newsimages/news5.jpg";
import news06 from "../../assets/images/newsimages/news6.jpg";
import NavBar from "../NavBar";
import SideBar from "../SideBar";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, EffectCoverflow, Pagination } from "swiper";
import { newsFeeds } from "./newsdata";

SwiperCore.use([Autoplay]);

function NewsPageContent() {
  let navigate = useNavigate();
  const redirectToNews = () => {
    window.open(
      "https://gulfnews.com/business/corporate-news/colossalbit-and-iwcb-to-launch-worlds-first-nft-linked-to-a-unique-fine-dining-and-art-experience-1.1635935496175",
      "_blank" // <- This is what makes it open in a new window.
    );
  };
  const news1 = () => {
    window.open(
      "https://cryptoinsight.ae/dubais-colossalbit-buys-nft-of-diego-maradonas-pen-used-for-napoli-contract-signing/",
      "_blank" // <- This is what makes it open in a new window.
    );
  };
  const news2 = () => {
    window.open(
      "https://www.thenationalnews.com/business/technology/2021/12/15/dubais-colossalbit-buys-diego-maradonas-dupont-pens-nft-for-26000/",
      "_blank" // <- This is what makes it open in a new window.
    );
  };
  const news3 = () => {
    window.open(
      "https://www.khaleejtimes.com/business/dubais-colossalbit-makes-history-by-winning-bid-of-56000-for-future-nft-dubai",
      "_blank" // <- This is what makes it open in a new window.
    );
  };
  const news4 = () => {
    window.open(
      "https://www.khaleejtimes.com/business/colossalbit-launches-mixed-reality-lounge-restaurant-called-metaterrace",
      "_blank" // <- This is what makes it open in a new window.
    );
  };
  const news5 = () => {
    window.open(
      "https://www.arabnews.com/node/1997296/media",
      "_blank" // <- This is what makes it open in a new window.
    );
  };
  return (
    <section className="wrapper-about">
      <div className="topbarmobile">
        <SideBar />
      </div>
      <div className="news-container align-sidebar-content">
        <h1 className="news_title">News & Events</h1>

        <div className="grid">
          <div className="grid__item grid__item-main">
            <img
              src={mainImg}
              alt="diomond not found"
              onClick={redirectToNews}
            />
            <div className="grid__item-content">
              <a
                href="https://gulfnews.com/business/corporate-news/colossalbit-and-iwcb-to-launch-worlds-first-nft-linked-to-a-unique-fine-dining-and-art-experience-1.1635935496175"
                target="_blank"
                rel="noreferrer"
              >
                <h1 onClick={redirectToNews}>
                  ColossalBit and IWCB to launch
                  <br /> world’s first NFT linked to a unique fine dining and
                  art experience
                </h1>
              </a>
              <p>
                The NFT, named Ciao Bella! will buy 30 diners an unforgettable
                experience featuring a dinner signed by renowned Michelin
                starred master chefs, rare beverages, luxury ingredients, and
                unique digital art; at the Bella Restaurant & Lounge in Dubai,
                on the occasion of the 12th Italian (Sustainable) Cuisine World
                Summit.
              </p>
              <span>Nov 3, 2021</span>
            </div>
          </div>

          <div>
            <div className="grid__item grid__item-flex">
              <img src={news01} alt="news not found" onClick={news1} />
              <div className="flex-content">
                <a
                  href="https://www.thenationalnews.com/business/technology/2021/12/15/dubais-colossalbit-buys-diego-maradonas-dupont-pens-nft-for-26000/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <h1>
                    Dubai’s ColossalBit buys NFT of Diego Maradona’s pen used
                    for Napoli contract signing
                  </h1>
                </a>

                <span>June 3, 2022</span>
              </div>
            </div>

            <div className="grid__item grid__item-flex">
              <img src={news02} alt="news not found" onClick={news2} />
              <div className="flex-content">
                <a
                  href="https://cryptoinsight.ae/dubais-colossalbit-buys-nft-of-diego-maradonas-pen-used-for-napoli-contract-signing/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <h1>
                    Dubai’s ColossalBit buys Diego Maradona’s DuPont pen's NFT
                    for $26,000
                  </h1>
                </a>

                <span>May 1, 2021</span>
              </div>
            </div>

            <div className="grid__item grid__item-flex">
              <img src={news03} alt="news not found" onClick={news3} />
              <div className="flex-content">
                <a
                  href="https://www.khaleejtimes.com/business/dubais-colossalbit-makes-history-by-winning-bid-of-56000-for-future-nft-dubai"
                  target="_blank"
                  rel="noreferrer"
                >
                  <h1>
                    Dubai’s ColossalBit makes history by winning bid of $56,000
                    for 'Future NFT Dubai'
                  </h1>
                </a>

                <span>Oct 15, 2021</span>
              </div>
            </div>

            <div className="grid__item grid__item-flex">
              <img src={news05} alt="news not found" onClick={news4} />
              <div className="flex-content">
                <a
                  href="https://www.khaleejtimes.com/business/colossalbit-launches-mixed-reality-lounge-restaurant-called-metaterrace"
                  target="_blank"
                  rel="noreferrer"
                >
                  <h1>
                    ColossalBit launches mixed reality lounge-restaurant called
                    MetaTerrace
                  </h1>
                </a>

                <span>Mar 22, 2022</span>
              </div>
            </div>
          </div>
        </div>

        <Swiper
          modules={[EffectCoverflow, Pagination]}
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={"auto"}
          loop={true}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          autoplay={{
            delay: 3000,
          }}
          spaceBetween={15}
          pagination={{
            clickable: true,
            bulletClass:
              "swiper-pagination-bullet feature-pagination-projectdetail",
          }}
          breakpoints={{
            // when window width is >= 320px
            320: {
              slidesPerView: 1,
              spaceBetween: 15,
            },

            767: {
              slidesPerView: 2,
              spaceBetween: 15,
            },

            1024: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
          }}
          className="mySwiper swiper-detail-container"
        >
          {newsFeeds.map((data, i) => {
            return (
              <SwiperSlide key={i}>
                <div className="container-mobile" key={data.id}>
                  <a href={data.link} target="_blank">
                    <div className="container">
                      <img
                        className="newsdetail-img"
                        src={data.image}
                        alt="icon"
                      />
                      <div className="news-heading">
                        <div className="news-title-swiper">{data.title}</div>
                      </div>
                    </div>
                  </a>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </section>
  );
}

export default NewsPageContent;
