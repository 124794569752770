import {faAngleRight, faBackward} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import DOMPurify from 'dompurify'
import React, {useCallback, useEffect, useState} from 'react'
import {Link, NavLink, useParams} from 'react-router-dom'
import SwiperCore, {Autoplay, Pagination} from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/react'
import SideBar from '../SideBar'
import './ProjectContent.css'
import {projects} from './ProjectData'

import {BsFillArrowRightCircleFill, BsGlobe} from 'react-icons/bs'
import '../projects.css'
import waterMark from '../../assets/projects/banners/watermark.png'

SwiperCore.use([Autoplay])

function ProjectDetailContent() {
	const params = useParams()
	const [data, setData] = useState([])
	const [filteredId, setFilterId] = useState(projects)
	const [projectLength, setProjectLength] = useState(0)
	// let navigate = useNavigate()

	const relatedProjects = useCallback(
		item => {
			if (item[0].type === 'nft') {
				const updatedList = projects.filter(item => {
					return item.type === 'nft' && item.id !== parseInt(params.id)
				})
				setData([...updatedList], updatedList.length)
				console.log('data', updatedList)
			} else if (item[0].type === 'meta') {
				const updatedList = projects.filter(item => {
					return item.type === 'meta' && item.id !== parseInt(params.id)
				})
				setData([...updatedList], updatedList.length)
				console.log('data', updatedList)
			} else if (item[0].type === 'blockchain') {
				const updatedList = projects.filter(item => {
					return item.type === 'blockchain' && item.id !== parseInt(params.id)
				})
				setData([...updatedList], updatedList.length)
				setProjectLength(updatedList.length)
			}
		},
		[params.id],
	)

	useEffect(() => {
		const filterWithProjects = projects.filter(project => project.id === parseInt(params.id))
		setFilterId(filterWithProjects)
		relatedProjects(filterWithProjects)
	}, [params.id, relatedProjects])

	// const redirectToContactPage = () => {
	//   navigate('/contactus')
	// }

	console.log(filteredId[0])

	return (
		<>
			<section className='projectdetail-bg'>
				<div className='topbarmobile'>
					<SideBar />
				</div>
				<div className='news-container'>
					<div className='align-back-btn'>
						<Link to='/projects'>
							{' '}
							<FontAwesomeIcon icon={faBackward} className='icon-back'></FontAwesomeIcon>
							<span className='font-face-bold'>BACK</span>
						</Link>
					</div>

					{/*  */}

					{filteredId[0].bannerType === 'video' && (
						<div className='mb-5'>
							<h1 className='detail-title font-face-black  '>EXPLORE THE METAVERSE</h1>

							<div className='video__container video__container-detail'>
								{!filteredId[0].noWatermark && (
									<img className='watermark' src={waterMark} alt={`watermark banner not found`} />
								)}
								<video
									className='project__card_video'
									src={filteredId[0].videoSrc}
									muted
									playsInline
									autoPlay
									loop
								/>
							</div>
						</div>
					)}

					<div className='detail__grid '>
						<div className='order-2 order-md-1' style={{display: 'grid', gap: '1rem'}}>
							<div className='top__content'>
								<h1 className='font-face-bold'> {filteredId[0].title} </h1>

								<a
									target='_blank'
									rel='noreferrer'
									href={filteredId[0].link}
									className='d-flex align-items-center gap-2'
									style={{color: 'rgb(32, 129, 226)'}}
								>
									<BsGlobe />
									<span className='typo' style={{color: 'rgb(32, 129, 226)'}}>
										{filteredId[0].linkText}
									</span>
								</a>

								<div className='divider mt-3' />

								<div className='content_ mt-3'>
									<h2 className='font-face-bold'>Platform</h2>
									<ul>
										<li className='typo' style={{fontWeight: 600}}>
											{filteredId[0].platform}
										</li>
									</ul>
								</div>

								<div className='content_  mt-5'>
									<h2 className='font-face-bold'>Technologies</h2>
									<ul>
										{filteredId[0].technology.map(({tech}) => (
											<li className='typo' style={{fontWeight: 600}}>
												{tech}
											</li>
										))}
									</ul>
								</div>
							</div>
						</div>
						<img
							className='order-1 order-md-2'
							src={filteredId[0].projectpc}
							width='100%'
							alt='main pic not found'
						/>
					</div>

					<div className='detail__grid mt-5'>
						<img src={filteredId[0].screenshot} width='100%' alt='main pic not found' />
						<div style={{display: 'grid', gap: '1rem'}}>
							<div className='top__content'>
								<h1 className='font-face-bold' style={{color: 'white'}}>
									{' '}
									{filteredId[0].title}{' '}
								</h1>
								<p
									className='text-white mt-4 typo'
									style={{fontWeight: 400}}
									dangerouslySetInnerHTML={{
										__html: DOMPurify.sanitize(filteredId[0].description),
									}}
								></p>

								{/* <button className='btn btn-contact-project mb-5' onClick={redirectToContactPage}>
                  Contact Us
                </button> */}
							</div>
						</div>
					</div>

					<div className='mt-5'>
						<h1 className='project_text'>Similar Projects</h1>
						<Swiper
							modules={[Pagination]}
							slidesPerView={'auto'}
							loop={true}
							autoplay={{
								delay: 5000,
							}}
							spaceBetween={30}
							pagination={{
								clickable: true,
								bulletClass: 'swiper-pagination-bullet feature-pagination-projectdetail',
							}}
							breakpoints={{
								320: {
									slidesPerView: 1,
									spaceBetween: 15,
								},
								767: {
									slidesPerView: 2,
									spaceBetween: 20,
								},
								1024: {
									slidesPerView: 3,
									spaceBetween: 30,
								},
							}}
							className='mySwiper swiper-detail-container'
						>
							{data.length > 1 &&
								data.map((data, i) => {
									return (
										<SwiperSlide key={i}>
											<div className='container-mobile' key={data.id}>
												<div key={data.id} className='project__card'>
													<NavLink to={`/projects/${data.id}`}>
														{data.bannerType === 'video' ? (
															<div className='video__container'>
																<video
																	className='project__card_video'
																	src={data.videoSrc}
																	muted
																	playsInline
																	autoPlay
																	loop
																/>
															</div>
														) : (
															<img
																className='project__card_image'
																src={data.banner}
																alt={`${data.title} banner not found`}
															/>
														)}

														<img
															className='project__card__logo'
															src={data.icon}
															alt={`${data.title} logo not found`}
														/>

														<div className='project__card_content  '>
															<h1 style={{color: '#FFD358', fontWeight: 'bold'}}>
																{data.title} {data.label}
															</h1>

															<p className='line-clamp '>{data.content}</p>
															<div className='project__card_arrow'>
																<div className='project__card_arrow_inner'>
																	<BsFillArrowRightCircleFill />
																</div>
															</div>
														</div>
													</NavLink>
												</div>
											</div>
										</SwiperSlide>
									)
								})}
						</Swiper>
					</div>
					{data.length === 1 &&
						data.map((data, i) => {
							return (
								<div key={i} className='row col-lg-4 col-sm-6 col-md-6 col-12'>
									<div className='container-mobile' key={data.id}>
										<NavLink to={`/projects/${data.id}`}>
											<div
												className='container'
												style={{
													backgroundImage: `url(${data.bg})`,
													backgroundPosition: 'center',
													backgroundSize: 'cover',
													backgroundRepeat: 'no-repeat',
													width: '100%',
													height: '300px',
													position: 'relative',
													borderRadius: '20px',
												}}
											>
												<img className='projectdetail-img' src={data.icon} alt='icon' />
												<div className='project-heading col-lg-10'>
													<div className='project-title'>{data.title}</div>
													<p className='project-content'>{data.content}</p>

													<FontAwesomeIcon
														icon={faAngleRight}
														className='icon-right-arrow'
													></FontAwesomeIcon>
												</div>
											</div>
										</NavLink>
									</div>
								</div>
							)
						})}
				</div>
			</section>
		</>
	)
}

export default ProjectDetailContent
