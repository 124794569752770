import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons'
import {faEnvelope, faMapMarker} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import emailjs from 'emailjs-com'
import React, {useRef, useState} from 'react'
import {useForm} from 'react-hook-form'
import './ContactFooter.css'
// import emailjs from '@emailjs/browser';
import Recaptcha from 'react-recaptcha'
import Select from 'react-select'
import EventModal from './EventModal'
import SideBar from './SideBar'

function ContactFooter() {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: {errors},
  } = useForm()
  const selectRef = React.useRef()
  const [selectedValue, setSelectedValue] = useState(3)
  const [show, setShow] = useState(false)
  const [item, setItem] = useState('')
  const [isVerified, setVerify] = useState(false)
  const [values, setValues] = useState(false)
  const [phoneNumber, setPhonenumber] = useState(false)
  const [message, setMessage] = useState('')

  const rx_phone = /\d+/
  const rx_email =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const handleShow = item => {
    setShow(true, item)
  }
  const handleClose = () => setShow(false)
  console.log(watch('example'))

  const form = useRef()
  const inputhandleChange = event => {
    if (rx_email.test(event.target.value)) {
      setValues(true)
    } else {
      setValues(true)
    }
  }
  const navigateToMap = () => {
    window.open(
      'https://www.google.com/maps/place/The+Tower+Plaza+Hotel+Dubai/@25.2177543,55.2762078,17z/data=!3m1!4b1!4m8!3m7!1s0x3e5f134e61f205fd:0x34f79bdab52cb6c3!5m2!4m1!1i2!8m2!3d25.2177543!4d55.2783965',
      '_blank', // <- This is what makes it open in a new window.
    )
  }
  const navigateToMail = () => {
    window.open(
      'mailto:info@colossalbit.io',
      '_blank', // <- This is what makes it open in a new window.
    )
  }
  const onSubmit = (data, e) => {
    if (isVerified) {
      e.preventDefault()
      console.log('data', data)
      console.log('form', form.current)
      console.log('selected', selectedValue)

      if (message.length > 200) {
        return
      }

      emailjs
        .sendForm(
          'service_hhq4gy9',
          'template_fut2245',
          form.current,
          'owFExOz4KXnGi87qT',
        )
        .then(
          result => {
            console.log(result)
            setItem('success')
            handleShow()
            reset()
          },
          error => {
            console.log(error.text)
            setItem('failed')
            handleShow()
          },
        )
    } else {
      handleShow()
      setItem('captcha')
    }
  }

  const callback = () => {
    console.log('captcha successfully loaded')
  }
  const verifyCallback = response => {
    if (response) {
      setVerify(true)
    }
  }
  const selectType = e => {
    setSelectedValue(e.value)
  }
  const customStyles = {
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
      // const color = chroma(data.color);
      console.log({data, isDisabled, isFocused, isSelected})
      return {
        ...styles,
        backgroundColor: isFocused ? '#999999' : null,
        color: '#fff',
      }
    },
    control: (base, state) => ({
      ...base,

      background: 'black',
      color: state.isSelected ? 'transparent' : '#fff',
      borderColor: state.isFocused ? '#fff' : '#fff',
      boxShadow: state.isFocused ? null : null,
      ':active': {
        backgroundColor: state.isSelected ? 'transparent' : 'transparent',
      },
      ':hover': {
        borderColor: state.isFocused ? '#fff' : '#fff',
      },
    }),
    menu: base => ({
      ...base,
      borderRadius: 0,
      marginTop: 0,
      background: 'black',
      color: '#fff',
      ':hover': {
        background: 'black',
        color: '#fff',
      },
    }),
    placeholder: base => {
      return {
        ...base,
        color: '#ffffff',
      }
    },
    menuList: base => ({
      ...base,
      padding: 0,
      background: 'black',
      color: '#fff',
      borderColor: '#fff',
      ':hover': {
        background: 'black',
      },
    }),
  }
  const options = [
    {id: '1', value: 'nftproject', label: 'NFT Project'},
    {id: '2', value: 'consulting', label: 'Consulting'},
    {id: '3', value: 'nftmarketplace', label: 'NFT Marketplace'},
    {id: '4', value: 'metaterrace', label: 'Metaterrrace'},
    {id: '5', value: 'gaming', label: 'Gaming project'},
    {id: '6', value: 'media', label: 'Media enquiry'},
  ]
  const tempOptions = options.map(option => ({
    value: option.value,
    label: option.label,
  }))
  return (
    <>
      <section className="footer-bg">
        <div className="topbarmobile">
          <SideBar />
        </div>
        <div className="container align-sidebar-content">
          <div className="row col-lg-12 col-md-12 col-sm-12 col-12 justify-content-center">
            <div>
              <h2 className="work-text text-center">Let's Work Together!</h2>
            </div>

            <div className="container col-lg-6 col-sm-6 col-md-6 col-12 align-content-footer">
              <h1 className="contact-us">Contact Us</h1>
              <div className="col-lg-12 col-sm-12 col-12 align-contact-us">
                <a href="mailto:info@colossalbit.io">
                  {' '}
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className="icons-contact-mail"
                  ></FontAwesomeIcon>
                  <span className="footer-text-info">info@colossalbit.io</span>
                </a>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon={faMapMarker}
                    className="icons-contact-direction"
                    onClick={navigateToMap}
                  ></FontAwesomeIcon>
                  <div className="footer-text-contact">
                    <span
                      className="footer-text-location"
                      onClick={navigateToMap}
                    >
                      1904, The Tower Plaza Hotel, <br />
                      Sheikh Zayed Road, <br />
                      Opp. Emirates Towers, <br />
                      Metro Station - TC <br />
                      Dubai UAE
                    </span>
                  </div>
                </div>
              </div>
              <h1 className="follow-us">Follow Us</h1>
              <div className="contact-icons-a">
                <div className="d-flex">
                  <a
                    href="https://instagram.com/colossalbit.io?utm_medium=copy_link"
                    target="blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faInstagram}
                      className="icons-contact"
                    ></FontAwesomeIcon>
                  </a>
                  <a
                    href="https://www.facebook.com/ColossalBit-105518128607174"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faFacebook}
                      className="icons-contact"
                    ></FontAwesomeIcon>
                  </a>
                  <a
                    href="https://twitter.com/ColossalBit?t=Im-sTeITLijVDouOH29EaA&s=09"
                    target="blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faTwitter}
                      className="icons-contact"
                    ></FontAwesomeIcon>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/colossalbit/"
                    target="blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faLinkedin}
                      className="icons-contact"
                    ></FontAwesomeIcon>
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCUWgEGeLqnASaaxaKRbvsuw/videos"
                    target="blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faYoutube}
                      className="icons-contact"
                    ></FontAwesomeIcon>
                  </a>
                </div>
              </div>
            </div>
            <div className="container align-content-form align-content-footer col-lg-6 col-sm-6 col-md-6 col-12">
              {errors.name || errors.phone || errors.email || errors.message ? (
                <div className="error text-center">
                  Please enter the details. Required fields are marked *
                </div>
              ) : null}
              <div className="form-data" id="form-data">
                <form
                  name="colossal"
                  ref={form}
                  onSubmit={handleSubmit(onSubmit)}
                  noValidate
                  autoComplete="off"
                  className="form-align"
                >
                  {/* <form ref={form} onSubmit={sendEmail} onSubmit={handleSubmit(onSubmit)} novalidate class="form-align"> */}
                  <div className="form-group mb-3">
                    <input
                      type="text"
                      {...register('name', {required: true})}
                      id="name"
                      name="name"
                      className="form-control"
                      placeholder="Full Name *"
                      required
                    ></input>
                  </div>
                  <div className="form-group mb-3">
                    <input
                      type="email"
                      {...register('email', {
                        required: true,
                        pattern:
                          /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                      })}
                      onChange={inputhandleChange}
                      id="email"
                      name="email"
                      className="form-control"
                      placeholder="Email *"
                      rows="5"
                      required
                    ></input>
                  </div>
                  {values && errors.email && (
                    <p className="error">Please enter valid email</p>
                  )}
                  <div className="form-group mb-3">
                    <input
                      type="number"
                      maxlength="12"
                      id="phone"
                      name="phone"
                      className="form-control"
                      placeholder="Phone Number"
                      required
                    ></input>
                  </div>
                  <div className="form-group mb-3">
                    <input
                      type="text"
                      id="who"
                      name="who"
                      className="form-control"
                      placeholder="Who You Are ?"
                      required
                    ></input>
                  </div>
                  <div className="form-group mb-3">
                    <Select
                      options={tempOptions}
                      styles={customStyles}
                      name={selectedValue}
                      id={selectedValue}
                      onChange={selectType}
                      value={options.find(obj => obj.value === selectedValue)}
                      placeholder="What are you looking for?"
                    />
                  </div>
                  <div className="form-group mb-5">
                    <textarea
                      rows={5}
                      cols={5}
                      {...register('message', {required: true})}
                      onChange={e => setMessage(e.target.value)}
                      id="message"
                      name="message"
                      className="form-control"
                      placeholder="Message *"
                      maxLength="2000"
                    ></textarea>
                    <p className="error" style={{marginTop: '10px'}}>
                      <span style={{color: message.length > 200 && 'red'}}>
                        {' '}
                        {message.length}{' '}
                      </span>
                      / 200 characters required
                    </p>
                  </div>
                  <Recaptcha
                    theme="dark"
                    sitekey="6LdigpUfAAAAAFX8M6bWR3xn6OuxHLDz3RprUrJV"
                    render="explicit"
                    onloadCallback={callback}
                    verifyCallback={verifyCallback}
                  />
                  <button
                    className="btn btn-contact mb-3"
                    type="submit"
                    formNoValidate
                  >
                    Contact Us
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        {show ? (
          <EventModal
            show={show}
            item={item}
            handleClose={handleClose}
            handleShow={handleShow}
          />
        ) : null}
      </section>
    </>
  )
}

export default ContactFooter
