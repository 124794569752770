import React from 'react'
import BlogComponent from '../components/FooterPages/BlogComponent';
import EventFooter from '../components/EventFooter';

function BlogPage() {
  return (
    <>
        <BlogComponent/>
        <EventFooter />
    </>
  )
}

export default BlogPage