import React from "react";
import "./NftConsultancy.css";
import SideBar from "../SideBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import metaicon1 from "../../assets/images/nftimages/metaverse1.png";
import metaicon2 from "../../assets/images/nftimages/metaverse2.png";
import metaicon3 from "../../assets/images/nftimages/metaverse3.png";

function MetaversePckComponent() {
  return (
    <section className="nftconsultant-bg">
      <div className="topbarmobile">
        <SideBar />
      </div>
      <div className="news-container">
        <div className="align-back-btn">
          <Link to="/projects">
            {" "}
            <FontAwesomeIcon
              icon={faBackward}
              className="icon-back"
            ></FontAwesomeIcon>
            <span className="font-face-bold">BACK</span>
          </Link>
        </div>
        <div className="metaverse-wrapper">
          <h1>METAVERSE PACKAGE</h1>
          {/* <p className="col-lg-8 col-sm-10 col-12 nftconsultant-paragraph">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </p> */}
          <div className="metapackage-content row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-5">
              <img src={metaicon1} alt="" className="w-full-img-meta" />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-5">
              <div className="metaversepck-heading">
                <ul className="text-white">
                  <li className="pb-5">
                      Build your Brand presence in the Metaverse
                  </li>
                  <li className="pb-5">
                      Reserve your brand name in the Crypto world < br />
                      (.eth, .crypto, .nft)
                  </li>
                  <li className="pb-5">
                      Manage your brand wallets and smart contract
                  </li>
                  <li className="pb-5">
                      Provide cold Storage with Multisig
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-5">
              <div className="metaversepck-heading">
              <ul className="text-white">
                  <li className="pb-5">
                      Get your brand Logo verified as NFT on twitter & < br /> other social media
                  </li>
                  <li className="pb-5">
                     Create your verified page on Opensea and other < br /> NFT maketplaces
                  </li>
                  <li className="pb-5">
                      Design art for your Brand or NFT collection in 3D
                  </li>
                  <li className="pb-5">
                      Create your first verified NFT collection or drop
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-5">
              <img src={metaicon2} alt="" className="w-full-img-meta" />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-5">
              <img src={metaicon3} alt="" className="w-full-img-meta" />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-5">
              <div className="metaversepck-heading">
              <ul className="text-white">
                  <li className="pb-5">
                      Reserve your spot by buying a Land in the Metaverse
                  </li>
                  <li className="pb-5">
                      Design, create and build your dedicated VR, AR, XR Room
                  </li>
                  <li className="pb-5">
                      We provide you with arich metaverse investment portfolio and guidance
                  </li>
                  <li className="pb-5">
                      We make you ready to enter the new world of NFT & metaverse
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MetaversePckComponent;
