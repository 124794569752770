import React from "react";
import "./NftConsultancy.css";
import SideBar from "../SideBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Marquee from 'react-fast-marquee'
import gulfnews from '../../assets/images/gulf-news.png'
import msn from '../../assets/images/msn.png'
import khaleej from '../../assets/images/kahleej.png'
import business from '../../assets/images/business.png'
import wtc from '../../assets/images/wtc.png'
import cmc from '../../assets/images/CMC.png'

function VideoComponent() {
  return (
    <>
    <section className="videointerview-bg">
      <div className="topbarmobile">
        <SideBar />
      </div>
      <div className="video-interview-container">
        <div className="align-back-btn">
          <Link to="/">
            {" "}
            <FontAwesomeIcon
              icon={faBackward}
              className="icon-back"
            ></FontAwesomeIcon>
            <span className="font-face-bold">BACK</span>
          </Link>
        </div>
        <div className="video-interview-wrapper">
          <h1>Video Interview</h1>
          <div className="grid">
            <div className="grid__item grid__item-main">
              <div className="grid__video-content">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/chCftleL5CY"  className="video-class" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <h4>@Gulfood Inspire 2022</h4>
                <div className="main-video-title">
                  Food in the Metaverse - from Grocery Shopping, Food Delivery to Restaurants
                </div>
                <span>
                  Mar 2, 2022
                </span>
              </div>
            </div>

            <div>
              <div className="grid__item grid__item-flex">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/LY4jwQCwh1I" className="video-class" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className="flex-content">
                  <h4 className="video-not-main-title">
                    Taking F&B Experiences to the Next Level with Help from the Metaverse
                  </h4>
                  <span>Mar 3, 2022</span>
                </div>
              </div>

              <div className="grid__item grid__item-flex">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/q2YW6It5Mbg" className="video-class" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className="flex-content">
                  <h4 className="video-not-main-title">
                   World's first real-world Metaverse venue coupled with Sandbox
                  </h4>
                  <span>Feb 1, 2022</span>
                </div>
              </div>
              
              <div className="grid__item grid__item-flex">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/7u8ShMcNqv0" className="video-class" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className="flex-content">
                  <h4 className="video-not-main-title">
                   ColossalBit Speech @CED2021 (CryptoExpoDubai.com)
                  </h4>
                  <span>Oct 15, 2021</span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className='featuredin-text-video'>AS FEATURED IN:</div>
    </section>
    <Marquee
     className='text-center text-sm  text-white'
     pauseOnHover
     speed={50}
     gradient={false}
  >
     <a href="https://gulfnews.com/business/corporate-news/dubais-colossalbit-makes-history-with-winning-bid-of-56000-for-the-worlds-first-augmented-reality-nft-mural-1.1634214873686" target="_blank"><img src={gulfnews} className="gulf-news-img" alt="news"></img></a>
    <img src={msn} className="msn" alt="news"></img>
    <a href="https://www.khaleejtimes.com/business/dubais-colossalbit-makes-history-by-winning-bid-of-56000-for-future-nft-dubai"  target="_blank"><img src={khaleej} className="" alt="news"/></a>
    <a href="https://www.arabianbusiness.com/industries/technology/dubai-to-launch-metaterrace-a-restaurant-that-straddles-the-virtual-and-physical-worlds" target="_blank"><img src={business} className="business" alt="news"></img></a>
    <a href="https://www.gulfood.com/speakers/christian-chalfoun" target="_blank"><img src={wtc} className="" alt="news"></img></a>
    <img src={cmc} className="coin-market-img" alt="news"></img>
  </Marquee>
    </>
  );
}

export default VideoComponent;
