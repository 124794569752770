import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay } from 'swiper'
import images from './images'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/scrollbar'

import './NewsPage.css'

SwiperCore.use([Autoplay])

function NewsPageStories() {
   console.log(images)
   return (
      <section className='wrapper'>
         <div className='news-container'>
            <div className='grid-stories'>
               <div className='stories-item'>
                  <h1>
                     Our beautiful <br /> stories
                  </h1>
                  <p>
                     People live better in big houses and in big clothes. I try
                     to contrast; life today is full of contrast. We have to
                     change!...
                  </p>
               </div>

               <div className='stories-item stories-item-big'>
                  <div className='desktop'>
                     <Swiper
                        slidesPerView={3}
                        loop={true}
                        autoplay={{
                           delay: 2500,
                        }}
                        spaceBetween={15}
                        className='mySwiper'
                     >
                        {images.map((img, i) => (
                           <SwiperSlide>
                              <figure key={i} className='fig'>
                                 <img src={img} alt=' not found' />
                                 <figcaption>
                                    Our beautiful <br /> stories
                                 </figcaption>
                              </figure>
                           </SwiperSlide>
                        ))}
                     </Swiper>
                  </div>

                  {/* mobile Devices */}
                  <div className='mobile-swiper'>
                     <Swiper
                        loop={true}
                        slidesPerView={2}
                        spaceBetween={15}
                        autoplay={{
                           delay: 2500,
                        }}
                        className='mySwiper custom'
                        breakpoints={{
                           // when window width is >= 320px
                           320: {
                              slidesPerView: 1,
                              spaceBetween: 10,
                           },

                           620: {
                              slidesPerView: 2,
                              spaceBetween: 10,
                           },
                        }}
                     >
                        {images.map((img, i) => (
                           <SwiperSlide>
                              <img src={img} alt=' not found' />
                           </SwiperSlide>
                        ))}
                     </Swiper>
                  </div>
               </div>
            </div>
         </div>
      </section>
   )
}

export default NewsPageStories
