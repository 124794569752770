import React, { useState, Fragment } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/effect-coverflow'
import 'swiper/css/pagination'
import SwiperCore, { EffectCoverflow, Autoplay, Pagination } from 'swiper/core'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'

SwiperCore.use([EffectCoverflow, Autoplay, Pagination])

const AutoSlider = ({ images }) => {
   const [lightBox, setLightBox] = useState({
      photoIndex: 0,
      isOpen: false,
   })

   const { photoIndex, isOpen } = lightBox

   return (
      <>
         <div>
            {isOpen && (
               <Lightbox
                  animationDisabled={false}
                  mainSrc={images[photoIndex]}
                  nextSrc={images[(photoIndex + 1) % images.length]}
                  prevSrc={
                     images[(photoIndex + images.length - 1) % images.length]
                  }
                  onCloseRequest={() =>
                     setLightBox({ ...lightBox, isOpen: false })
                  }
                  onMovePrevRequest={() =>
                     setLightBox({
                        ...lightBox,
                        photoIndex:
                           (photoIndex + images.length - 1) % images.length,
                     })
                  }
                  onMoveNextRequest={() =>
                     setLightBox({
                        ...lightBox,
                        photoIndex: (photoIndex + 1) % images.length,
                     })
                  }
               />
            )}
         </div>
         <div className='autoSlider'>
            <Swiper
               effect={'coverflow'}
               centeredSlides={true}
               slidesPerView={3}
               loop={true}
               onChange={e => console.log(e)}
               autoplay={{
                  delay: 3000,
               }}
               pagination={{ clickable: true }}
               coverflowEffect={{
                  stretch: 0,
                  depth: 100,
                  modifier: 1,
                  slideShadows: true,
               }}
               className='mySwiper w-100 my-slider'
               // style={{overflow: 'inherit'}}
            >
               {images.map((image, index) => (
                  <SwiperSlide key={index}>
                     <img
                        className='slider-img'
                        src={image}
                        alt='slider Not Found'
                        onClick={() =>
                           setLightBox({ photoIndex: index, isOpen: true })
                        }
                     />
                  </SwiperSlide>
               ))}
            </Swiper>
         </div>
      </>
   )
}

export default AutoSlider
