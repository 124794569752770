import news1 from '../../assets/images/newsimages/newsswiper/img1.png'
import news2 from '../../assets/images/newsimages/newsswiper/img2.png'
import news3 from '../../assets/images/newsimages/newsswiper/img3.png'
import news4 from '../../assets/images/newsimages/newsswiper/img4.png'
import news5 from '../../assets/images/newsimages/newsswiper/img5.png'
import news6 from '../../assets/images/newsimages/newsswiper/img6.png'
import news7 from '../../assets/images/newsimages/newsswiper/img7.jpg'
import news8 from '../../assets/images/newsimages/newsswiper/img8.jpg'
import news9 from '../../assets/images/newsimages/newsswiper/img9.jpg'
import news10 from '../../assets/images/newsimages/newsswiper/img10.png'
import news11 from '../../assets/images/newsimages/newsswiper/img11.png'
import news12 from '../../assets/images/newsimages/newsswiper/img12.png'
import news13 from '../../assets/images/newsimages/newsswiper/img13.png'

export const newsFeeds = [
    {  
       id:1,
       image: news1,
       title: "ColossalBit launches a one-of-a-kind mixed reality lounge-restaurant called MetaTerrace",
       link: 'https://dubaidiaries.com/2021/12/metaterrace-clossalbit/'
    },
    {
       id:2,
       image: news2,
       title: "Dubai’s ColossalBit buys Diego Maradona’s DuPont pen's NFT for $26,000MetaTerrace: step into the future at a metaverse-themed restaurant in Dubai’s DIFC",
       link: 'https://www.msn.com/en-ae/lifestyle/other/metaterrace-step-into-the-future-at-a-metaverse-themed-restaurant-in-dubai-s-difc/ar-AASf35N'
    },
   
    { 
      id:3,
      image: news4,
      title: "Weekly Wrap: What's Happening in the Metaverse This Week? [Dec. 30, 2021]",
      link: "https://coinmarketcap.com/alexandria/article/weekly-wrap-what-s-happening-in-the-metaverse-this-week-dec-30-2021",
     },
     {  
      id:4,
      image: news3,
      title: "ColassalBit Launches Mixed Reality Lounge-Restaurant MetaTerrace",
      link: "https://nftevening.com/colassalbit-launches-mixed-reality-lounge-restaurant-metaterrace/",
   },
     {
      id:5,
      image: news5,
      title: "METAVERSEDecember 29, 2021MetaTerrace, first metaverse restaurant launched by ColossalBit",
      link: "https://metanews.com/new-years-eve-is-happening-in-the-metaverse-this-year/",
   },
   {  
      id:6,
      image: news6,
      title: "Dubai’s Latest Fusion Lounge With A VR Room Will Make You Step Into The Future",
      link: "https://curlytales.com/dubais-latest-fusion-lounge-with-a-vr-room-will-make-you-step-into-the-future/",
   },
   {  
      id:7,
      image: news7,
      title: "ColossalBit launches Dubai’s first metaverse restaurant",
      link: "https://www.arabnews.com/node/1997296/media",
   },
   {  
      id:8,
      image: news8,
      title: "Metaverse: An emerging immersive digital land with boundless opportunities",
      link: "https://www.arabianbusiness.com/gcc/metaverse-an-emerging-immersive-digital-land-with-boundless-opportunities?utm_medium=email",
   },
   {  
      id:9,
      image: news9,
      title: "Experience the Metaverse in Dubai",
      link: "https://www.bbcgoodfoodme.com/news/metaverse-metaterrace-difc-dubai/",
   },
   {  
      id:10,
      image: news10,
      title: "Residents and tourists can dine in the metaverse at a Dubai restaurant -MetaTerrace to get a taste of virtual reality",
      link: "https://magzoid.com/residents-and-tourists-can-dine-in-the-metaverse-at-a-dubai-restaurant-metaterrace-to-get-a-taste-of-virtual-reality/",
   },
   {  
      id:11,
      image: news11,
      title: "Metaverse In Dubai: ColossalBit Launches A One-Of-A-Kind Mixed",
      link: "https://www.cryptured.com/metaverse-in-dubai-colossalbit-launches-a-one-of-a-kind-mixed-reality-lounge-restaurant-called-metaterrace/",
   },
   {  
      id:12,
      image: news12,
      title: "Metaverse is going to be ‘colossal’ for the global economy",
      link: "https://www.tahawultech.com/interviews/metaverse-is-going-to-be-colossal-for-the-global-economy/amp/",
   },
   {  
      id:13,
      image: news13,
      title: "MetaTerrace: step into the future at a metaverse-themed restaurant in Dubai's DIFC",
      link: "https://vnexplorer.net/metaterrace-step-into-the-future-at-a-metaverse-themed-restaurant-in-dubais-difc-ek20213759066.html",
   }
 ]