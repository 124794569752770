import React from 'react'
import './EventOffer.css'
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";

function EventOffer() {
 
  return (
    <>
    <section className='event-offer-bg'>
      <div className='container'>
      <header>
         We are the leaders in NFT Consulting! 
      </header>
      <div className='offer-text'>
         Deep Dive into our services 
      </div>
      <div className="justify-content-center offer-section container col-lg-10 col-md-12 col-sm-12">
        
        <div className="offer-content col-md-3 col-sm-3 col-lg-3 col-12">
            <div className="education-icon"></div>
            <h4>Education, exploration & consulting</h4>
            <p className='text-center per-project'>Per Project Basis</p>
           <div className='offer-content-middle'><p>
           Curious about the NFT space, and how it could work for your business? We provide workshops customized for you. An immersive session or sessions, focused on educating your team on the Crypto space, NFTs, and the Metaverse landscape; brainstorming how it could come to life for you & your business.
            </p></div>
            <div className='footer-offer-text col-md-9'>Base Investment</div>
        </div>
        <div className="offer-content col-md-3 col-sm-3 col-lg-3 col-12">
            <div className='design-icon'></div>
            <h4>Design, building & customization</h4>
            <p className='text-center per-project'>Per Project Basis</p>
            <div className='offer-content-middle'><p>
            We Design Tailored NFT projects with intent to excite and entice consumers to buy your NFT via:
            </p>
            <p>
                ◦ Holistic creative wrapper around your brand <br />
                ◦ Our Experienced Technical Partners <br />
                ◦ Audience-driven and utility-focused ideation & design <br />
                ◦ KPIs to measure success <br />
            </p>
            </div>
            <div className='footer-offer-text'>Base Investment + NFT Percentage</div>
        </div>
        <div className="offer-content col-md-3 col-sm-3 col-lg-3 col-12">
            <div className='market-icon'></div>
            <h4>Market approach & strategy</h4>
            <p className='text-center per-project'>Per Project Basis</p>
            <div className='offer-content-middle'><p>
            We help you understand NFT market needs and work closely with you on a market approach in order to make your NFT project successful by:
            </p>
            <p>
                 ◦ Analyzing your market niche <br />
                 ◦ Laying out multiple scenario and option for you to choose <br />
                 ◦ Guiding on the best trend and approach based on our experience <br />
                 ◦ KPIs to measure success <br />
              </p>
            </div>
            <div className='footer-offer-text'>Base Investment + NFT Percentage</div>
        </div>
        <div className="offer-content col-md-3 col-sm-3 col-lg-3 col-12">
            <div className='investment-icon'></div>
            <h4>Full NFT partnership & investment</h4>
            <p className='text-center'>Per Project Basis or Ongoing Partnership</p>
            <div className='offer-content-middle'><p>
            We provide in-depth expertise with strategic thinking to determine the best way to bring your NFT to market, including:
            </p>
            <p>
                 ◦ Asset development & creation <br />
                 ◦ Launch strategy <br />
                 ◦ Blockchain selection <br />
                 ◦ Pricing, sale and support <br />
              </p>
            </div>
            <div className='footer-offer-text'>Base Investment + NFT Percentage</div>
        </div>
      
      </div>
      {/* <div className='showMobile'>
       <div className='container'>
       
        <div className="offer-content col-md-3 col-sm-3">
        <div className="education-icon"></div>
            <h4>Education, exploration & consulting</h4>
            <p className='text-center'>Per Project Basis</p>
           <div className='offer-content-middle'><p>
            What is an NFT? If you think an NFT is just a picture or jpg the you’re missing the point!Curious about the NFT space, and how it could work for your business? We provide workshops customized for you. An immersive session or sessions, focused on educating your team on the Crypto space, NFTs, and the Metaverse landscape; brainstorming how it could come to life for you & your business
            </p></div>
            <div className='footer-offer-text col-9'>Base Investment</div>
        </div>
      
        <div className="offer-content col-md-3 col-sm-3">
        <div className='design-icon'></div>
            <h4>Design, building & customization</h4>
            <p className='text-center'>Per Project Basis</p>
            <div className='offer-content-middle'><p>
            We Design Tailored NFT projects with intent to excite and entice consumers to buy your NFT via:
            </p>
            <ul>
                <li>Holistic creative wrapper around your brand</li>
                <li>Our Experienced Technical Partners</li>
                <li>Audience-driven and utility-focused ideation & design</li>
                <li>KPIs to measure success</li>
            </ul></div>
            <div className='footer-offer-text'>Base Investment + NFT Percentage</div>
        </div>
       
        <div className="offer-content col-md-3 col-sm-3">
        <div className='market-icon'></div>
            <h4>Market approach & strategy</h4>
            <p className='text-center'>Per Project Basis</p>
            <div className='offer-content-middle'><p>
            We help you understand NFT market needs and work closely with you on a market approach in order to make your NFT project successful by:
            </p>
            <ul>
                <li>Analyzing your market niche</li>
                <li>Laying out multiple scenario and option for you to choose</li>
                <li>Guiding on the best trend and approach based on our experience</li>
                <li>KPIs to measure success</li>
            </ul></div>
            <div className='footer-offer-text'>Base Investment + NFT Percentage</div>
        </div>

        <div className="offer-content col-md-3 col-sm-3">
        <div className='investment-icon'></div>
            <h4>Full NFT partnership & investment</h4>
            <p className='text-center'>Per Project Basis or Ongoing Partnership</p>
            <div className='offer-content-middle'><p>
            We marry in-depth expertise with strategic thinking to determine the best way to bring your NFT to market, including
            </p>
            <ul>
                <li>Asset development & creation</li>
                <li>Launch strategy</li>
                <li>Blockchain selection</li>
                <li>Pricing, sale and support</li>
            </ul>
            </div>
            <div className='footer-offer-text'>Base Investment + NFT Percentage</div>
        </div>
      </div>
    </div> */}
       </div>
    </section>
    </>
  )
}

export default EventOffer